export const unidadesMedida = [
  { codigo: 1, abreviatura: "BARR", medida: "Barril" },
  { codigo: 2, abreviatura: "BOL", medida: "Bolsa" },
  { codigo: 3, abreviatura: "BOT", medida: "Bote" },
  { codigo: 4, abreviatura: "BULTO", medida: "Bultos" },
  { codigo: 5, abreviatura: "BOTELLA", medida: "Botella" },
  { codigo: 6, abreviatura: "CAJ", medida: "Caja" },
  { codigo: 7, abreviatura: "CAJETILLA", medida: "Cajetilla" },
  { codigo: 8, abreviatura: "CM", medida: "Centímetro" },
  { codigo: 9, abreviatura: "CIL", medida: "Cilindro" },
  { codigo: 10, abreviatura: "CONJ", medida: "Conjunto" },
  { codigo: 11, abreviatura: "CONT", medida: "Contenedor" },
  { codigo: 12, abreviatura: "DIA", medida: "Día" },
  { codigo: 13, abreviatura: "DOC", medida: "Docena" },
  { codigo: 14, abreviatura: "FARD", medida: "Fardo" },
  { codigo: 15, abreviatura: "GL", medida: "Galones" },
  { codigo: 16, abreviatura: "GRAD", medida: "Grado" },
  { codigo: 17, abreviatura: "GR", medida: "Gramo" },
  { codigo: 18, abreviatura: "GRAN", medida: "Granel" },
  { codigo: 19, abreviatura: "HOR", medida: "Hora" },
  { codigo: 20, abreviatura: "HUAC", medida: "Huacal" },
  { codigo: 21, abreviatura: "KG", medida: "Kilogramo" },
  { codigo: 22, abreviatura: "kWh", medida: "Kilovatio Hora" },
  { codigo: 23, abreviatura: "LB", medida: "Libra" },
  { codigo: 24, abreviatura: "LITRO", medida: "Litro" },
  { codigo: 25, abreviatura: "LOT", medida: "Lote" },
  { codigo: 26, abreviatura: "M", medida: "Metro" },
  { codigo: 27, abreviatura: "M2", medida: "Metro Cuadrado" },
  { codigo: 28, abreviatura: "M3", medida: "Metro Cúbico" },
  { codigo: 29, abreviatura: "MMBTU", medida: "Millones de Unidades Térmicas" },
  { codigo: 30, abreviatura: "MIN", medida: "Minuto" },
  { codigo: 31, abreviatura: "PAQ", medida: "Paquete" },
  { codigo: 32, abreviatura: "PAR", medida: "Par" },
  { codigo: 33, abreviatura: "PIE", medida: "Pie" },
  { codigo: 34, abreviatura: "PZA", medida: "Pieza" },
  { codigo: 35, abreviatura: "ROL", medida: "Rollo" },
  { codigo: 36, abreviatura: "SOBR", medida: "Sobre" },
  { codigo: 37, abreviatura: "SEG", medida: "Segundo" },
  { codigo: 38, abreviatura: "TANQUE", medida: "Tanque" },
  { codigo: 39, abreviatura: "TONE", medida: "Tonelada" },
  { codigo: 40, abreviatura: "TUB", medida: "Tubo" },
  { codigo: 41, abreviatura: "YD", medida: "Yarda" },
  { codigo: 42, abreviatura: "YD2", medida: "Yarda cuadrada" },
  { codigo: 43, abreviatura: "UND", medida: "Unidad" },
  { codigo: 44, abreviatura: "EA", medida: "Elemento" },
  { codigo: 45, abreviatura: "MILLAR", medida: "Millar" },
  { codigo: 46, abreviatura: "SAC", medida: "Saco" },
  { codigo: 47, abreviatura: "LAT", medida: "Lata" },
  { codigo: 48, abreviatura: "DIS", medida: "Display" },
  { codigo: 49, abreviatura: "BID", medida: "Bidón" },
  { codigo: 50, abreviatura: "RAC", medida: "Ración" },
  { codigo: 51, abreviatura: "Q", medida: "Quintal" },
  { codigo: 52, abreviatura: "GRT", medida: "Gross Register Tonnage" },
  { codigo: 53, abreviatura: "P2", medida: "Pie cuadrado" },
  { codigo: 54, abreviatura: "PAX", medida: "Pasajero" },
  { codigo: 55, abreviatura: "PULG", medida: "Pulgadas" },
  { codigo: 56, abreviatura: "STAY", medida: "Parqueo barcos en muelle" },
  { codigo: 57, abreviatura: "BDJ", medida: "Bandeja" },
];

export type UnidadMedida = (typeof unidadesMedida)[number];

export const getAbbreviatura = (codigo?: number | string) => {
  if (!codigo) return "";

  const unidad = unidadesMedida.find((unidad) => +unidad.codigo === +codigo);
  return unidad ? unidad.abreviatura : String(codigo);
};
