import { Grid } from '@material-ui/core';
import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { TextField } from '../../../common/Inputs';
import MensajeAlerta from '../../../common/mensajeAlerta/mensajeAlerta';
import { Ejecutar } from '../../../common/server/funcionesServidor';
import { validarValorAsignado } from '../../../common/validaciones/validaciones';

function ModalResetearContrasena({
  onCerrarAbrirModal,
  toggleResetearContrasena }
  : {
    onCerrarAbrirModal: () => void;
    toggleResetearContrasena: () => void;
  }) {
  const [data, setData] = useState({
    clave: "",
    claveConfirmar: "",
    resetToken: "",
  });

  const onInputChange = ({
    target: { name, value },
  }: {
    target: {
      name: string;
      value: string;
    };
  }) => {
    const nvalue = value;

    setData({ ...data, [name]: nvalue });
  }

  const onChangePassword = async () => {
    //Validaciones
    let error = {};
    error = {
      ...error,
      ...validarValorAsignado(
        "nombre",
        data.clave,
        "Debe indicar una contraseña"
      ),
    };

    error = {
      ...error,
      ...validarValorAsignado(
        "nombre",
        data.claveConfirmar,
        "Debe indicar confirmar la contraseña"
      ),
    };

    error = {
      ...error,
      ...validarValorAsignado(
        "nombre",
        data.resetToken,
        "Debe indicar un codigo"
      ),
    };

    if (data.clave !== data.claveConfirmar) {
      error = {
        ...error,
        clave: 'Las contraseñas no son iguales'
      };
      MensajeAlerta("error", "Las contraseñas no son iguales")
    }

    if (JSON.stringify(error) !== "{}") {
      return;
    }
    //Validaciones

    const respuesta = await Ejecutar(
      "api/OrdenServicioTecnicos/resetpasswordwithtoken",
      null,
      data,
      "Contraseña reiniciada",
      undefined,
      true
    );

    if (respuesta.length) {
      onCerrarAbrirModal();
      toggleResetearContrasena();
    }
  }
  return (
    <>
      <Modal isOpen={true} zIndex={2000}>
        <ModalHeader style={{ backgroundColor: 'white', color: 'black' }}>Reiniciar contraseña</ModalHeader>
        <ModalBody>
          <Grid container item spacing={1}>
            <Grid item xs={12} id="clave">
              <TextField
                name="clave"
                label="Contraseña Nueva"
                onChange={onInputChange}
                value={data.clave}
                required
                type='password'
              />
            </Grid>
            <Grid item xs={12} id="claveConfirmar">
              <TextField
                name="claveConfirmar"
                label="Confirmar Contraseña"
                onChange={onInputChange}
                value={data.claveConfirmar}
                required
                type='password'
              />
            </Grid>
            <Grid item xs={12} id="resetToken">
              <TextField
                name="resetToken"
                label="Codigo"
                onChange={onInputChange}
                value={data.resetToken}
                required
                autoComplete={'off'}
              />
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onChangePassword} color="success">
            Confirmar
          </Button>{" "}
          <Button onClick={onCerrarAbrirModal} color="danger">
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ModalResetearContrasena