import React, { useEffect } from "react";
import { FallbackProps } from "react-error-boundary";
import { Button, Grid, Typography } from "@mui/material";
import { Ejecutar } from "./common/server/funcionesServidor";
import { useLocation } from "react-router-dom";
import { TextField } from "./common/Inputs";
import { useState } from "react";
import { Send } from "@material-ui/icons";
import { IAuthContext } from "../AppInterfaces";
import { localStorageName } from "./MainComponent";
import { error3 } from "../imagenes";

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  const location = useLocation();
  const [loginState, setLoginState] = useState<IAuthContext>();
  const [descripcionError, setDescripcionError] = useState("");
  const [mensajeDescripcionEnviado, setMensajeDescripcionEnviado] =
    useState(false);

  useEffect(() => {
    const storage = localStorage.getItem(localStorageName);

    if (!storage) return;

    const loginState: IAuthContext = JSON.parse(storage);
    setLoginState(loginState);

    Ejecutar("api/error", undefined, {
      companiaId: loginState.companiaSeleccionada.id,
      companiaNombre: loginState.companiaSeleccionada.nombre,
      mensaje: error.message + " Stack: " + error.stack,
      ruta: location.pathname,
    });
  }, [setLoginState, error.message, error.stack, location.pathname]);

  const enviarDescripcion = () => {
    if (!loginState) return;

    Ejecutar("api/error", undefined, {
      companiaId: loginState.companiaSeleccionada.id,
      companiaNombre: loginState.companiaSeleccionada.nombre,
      mensaje: error.message + " Stack: " + error.stack,
      ruta: location.pathname,
      descripcionDelUsuario: descripcionError,
    });

    setMensajeDescripcionEnviado(true);
  };

  return (
    <Grid
      container
      style={{ height: "100vh" }}
      justifyContent="center"
      alignItems="center"
      direction="row-reverse"
    >
      <Grid item xs={12} sm={4}>
        <img src={error3} alt="error" />
      </Grid>

      <Grid item xs={12} sm={8}>
        <Grid container justifyContent="center" style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <Typography variant="h3" color="primary" gutterBottom>
              Ups, algo salió mal.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4" color="primary" gutterBottom>
              Recibimos el error y estaremos trabajando en solucionarlo.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" color="primary" gutterBottom>
              Envíanos una breve descripción del error:
            </Typography>
          </Grid>

          {!mensajeDescripcionEnviado ? (
            <>
              <Grid item xs={12} sm={8}>
                <TextField
                  name="descripcionError"
                  placeholder="Cuando ocurrió el error pulsé el botón X cuando digitaba un Y..."
                  onChange={(e) => setDescripcionError(e.target.value)}
                  rows={3}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  style={{
                    width: 250,
                    backgroundColor: "green",
                    color: "white",
                    marginBottom: 50,
                    borderRadius: 20,
                  }}
                  onClick={enviarDescripcion}
                  variant="outlined"
                >
                  <Typography variant="h6" color="inherit">
                    Enviar Descripción
                  </Typography>
                  <Send fontSize="large" />
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom style={{ color: "green" }}>
                Gracias por su tiempo. Revisaremos su situación lo antes
                posible.
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              style={{
                width: 250,
                backgroundColor: "red",
                color: "white",
                borderRadius: 20,
              }}
              variant="outlined"
              onClick={resetErrorBoundary}
            >
              <Typography variant="h6" color="inherit">
                Regresar a la aplicación. 🔄
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
