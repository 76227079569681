import React, { useState } from "react";
import { AddCircle, DeleteForever } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Dialog from "../../../../../common/dialog/dialog";
import { TextField } from "../../../../../common/Inputs";

import {
  IOrdenServicioOrden,
  IOrdenServicioOrdenTecnico,
  IOrdenServicioTecnico,
} from "../../../../../../AppInterfaces";

import {
  CardContent,
  Grid,
  Card,
  CardHeader,
  Table,
  TableRow,
  TableHead,
  TableCell,
  IconButton,
  TableBody,
  Button,
} from "@material-ui/core";

export default function Tecnicos({
  documento,
  setDocumento,
  setDocumentoModificado,
  tecnicos,
  modificandoAgregandoDocumento,
  usuarioId,
  companiaId,
}: {
  documento: IOrdenServicioOrden;
  setDocumento: (estado: IOrdenServicioOrden) => void;
  setDocumentoModificado: (estado: boolean) => void;
  tecnicos: IOrdenServicioTecnico[];
  usuarioId: number;
  companiaId: number;
  modificandoAgregandoDocumento: boolean;
}) {
  const [lineaEliminar, setLineaEliminar] = useState<{
    registro?: IOrdenServicioOrdenTecnico;
    indice?: number;
  }>();

  const agregarLinea = () => {
    const nuevoEstado: IOrdenServicioOrden = JSON.parse(
      JSON.stringify(documento)
    );
    nuevoEstado.tecnicos.push({ tecnicoId: 0, tecnicoNombre: "", creadoPorId: usuarioId, companiaId: companiaId, tecnicoEmail: "", });
    setDocumento(nuevoEstado);
    setDocumentoModificado(true);
  };

  const eliminarLinea = () => {
    const nuevoEstado: IOrdenServicioOrden = JSON.parse(
      JSON.stringify(documento)
    );
    nuevoEstado.tecnicos[lineaEliminar?.indice ?? 0].eliminar = true;
    setDocumento(nuevoEstado);
    setDocumentoModificado(true);
    setLineaEliminar(undefined);
  };

  return (
    <>
      {lineaEliminar &&
        lineaEliminar.registro &&
        lineaEliminar.indice !== undefined && (
          <Dialog
            open
            titulo="¡Advertencia!"
            estiloTitulo="Warning"
            mensaje={`¿Seguro desea eliminar el registro ${lineaEliminar.registro.tecnicoNombre
              }, Fila: ${lineaEliminar.indice + 1}?`}
            textoBtn1="Continuar y Eliminar Registro"
            textoBtn2="Cancelar"
            accionDialogBtn1={() => eliminarLinea()}
            accionDialogBtn2={() => setLineaEliminar(undefined)}
          />
        )}

      <Grid item xs={12}>
        <Card className="card" id="cardTecnicos">
          <CardHeader title="Tecnicos Asignados" />
          <CardContent>
            {
              modificandoAgregandoDocumento ? (
                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Acciones</TableCell>
                          <TableCell>Técnico</TableCell>
                        </TableRow>
                      </TableHead>
                      {documento?.tecnicos ? (
                        <TableBody>
                          {documento.tecnicos.map(
                            (registro, indice) =>
                              !registro.eliminar && (
                                <TableRow key={indice}>
                                  <TableCell>
                                    <IconButton
                                      onClick={() =>
                                        setLineaEliminar({ registro, indice })
                                      }
                                    >
                                      <DeleteForever />
                                    </IconButton>
                                  </TableCell>

                                  <TableCell>
                                    <Grid item xs={12} id="tecnicoNombre">
                                      <Autocomplete
                                        options={tecnicos.filter((e) => documento.tecnicos.filter((s) => s.eliminar === undefined).some((d) => d.tecnicoId === e.id) === false).map((tecnicos) => ({
                                          tecnicoId: tecnicos.id ?? 0,
                                          tecnicoNombre: tecnicos.nombre,
                                          tecnicoEmail: tecnicos.email,

                                        }))}
                                        getOptionLabel={(option) =>
                                          option.tecnicoNombre
                                        }
                                        getOptionSelected={(option, value) =>
                                          JSON.stringify(option) ===
                                          JSON.stringify(value)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            params={{ ...params }}
                                            name="tecnicoNombre"
                                          />
                                        )}
                                        onChange={(event, value) => {
                                          if (value) {
                                            const nuevoEstado: IOrdenServicioOrden =
                                              JSON.parse(JSON.stringify(documento));
                                            nuevoEstado.tecnicos[
                                              indice
                                            ].tecnicoId = value.tecnicoId;
                                            nuevoEstado.tecnicos[
                                              indice
                                            ].tecnicoNombre = value.tecnicoNombre;
                                            nuevoEstado.tecnicos[
                                              indice
                                            ].tecnicoEmail = value.tecnicoEmail;
                                            setDocumento(nuevoEstado);
                                          }
                                        }}
                                        value={{
                                          tecnicoId: registro.tecnicoId,
                                          tecnicoNombre: registro.tecnicoNombre,
                                          tecnicoEmail: registro.tecnicoEmail,
                                        }}
                                      />
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              )
                          )}
                        </TableBody>
                      ) : null}
                    </Table>

                    <Button
                      fullWidth
                      id="botonMasDetalle"
                      onClick={() => agregarLinea()}
                    >
                      <AddCircle />
                      Agregar Linea
                    </Button>
                  </Grid>
                </Grid>
              ) : <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                      </TableRow>
                    </TableHead>
                    {documento?.tecnicos ? (
                      <TableBody>
                        {documento.tecnicos.map(
                          (registro, indice) =>
                            !registro.eliminar && (
                              <TableRow key={indice}>
                                <TableCell>
                                  <Grid item xs={12} id="tecnicoNombre">
                                    {registro.tecnicoNombre}
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            )
                        )}
                      </TableBody>
                    ) : null}
                  </Table>
                </Grid>
              </Grid>
            }
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
