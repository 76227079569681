import React, { Fragment, useState } from "react";
import classes from "./Avatar.module.css";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DialogMessage from "../../common/dialog/dialog";
import { NavLink } from "react-router-dom";
import {
  Person,
  PowerSettingsNew,
  Business,
  Folder,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import * as Avatares from "../../common/avatares/avatares";

/**
 * presenta la seccion del avatar del usuario junto a sus opciones
 * @param NombreUsuario nombre del usuario a mostrar
 * @param imgUsuario imagen del usuario a mostrar
 * @param LogoutUser metodo que desconecta el usuario
 * @param authCompaniaSeleccionadaActualizar metodo para cambiar de compañía
 */
export default function Avatar({
  authCompaniaSeleccionadaActualizar,
  LogoutUser,
  NombreUsuario,
  imgUsuario,
  avatarUsuario,
  rutaAvatarDefault,
}: {
  authCompaniaSeleccionadaActualizar: () => void;
  LogoutUser: () => void;
  NombreUsuario: string;
  imgUsuario: string;
  avatarUsuario: string;
  rutaAvatarDefault: string;
}) {
  const [MostrarDialogSalir, setMostrarDialogSalir] = useState(false);

  const cambiarCompania = () => {
    authCompaniaSeleccionadaActualizar();
  };

  const continuoDocs = () => {
    return window.open("https://clickteckrd.com/continuoDocs/");
  };

  const SalirClick = () => setMostrarDialogSalir(true);

  const AccionDialogBtn1 = () => {
    setMostrarDialogSalir(false);
    LogoutUser();
  };

  const AccionDialogBtn2 = () => {
    setMostrarDialogSalir(false);
  };

  return (
    <Fragment>
      <DialogMessage
        open={MostrarDialogSalir}
        titulo="¡Advertencia!"
        estiloTitulo="Warning"
        mensaje="¿Seguro desea salir de la aplicación?"
        textoBtn1="Salir de Aplicación"
        textoBtn2="Cancelar"
        accionDialogBtn1={AccionDialogBtn1}
        accionDialogBtn2={AccionDialogBtn2}
      />

      <UncontrolledDropdown>
        <DropdownToggle tag="span">
          <span className={classes.nomUsuario}>{NombreUsuario}</span>
          {imgUsuario ? (
            <img
              className={classes.imgProfile}
              src={"data:image/png;base64," + imgUsuario}
              alt="Imagen Usuario"
            />
          ) : avatarUsuario === "avatar1" ? (
            <IconButton>{Avatares.avatar1(45, 45)}</IconButton>
          ) : avatarUsuario === "avatar2" ? (
            <IconButton>{Avatares.avatar2(45, 45)}</IconButton>
          ) : avatarUsuario === "avatar3" ? (
            <IconButton>{Avatares.avatar3(45, 45)}</IconButton>
          ) : avatarUsuario === "avatar4" ? (
            <IconButton>{Avatares.avatar4(45, 45)}</IconButton>
          ) : avatarUsuario === "avatar5" ? (
            <IconButton>{Avatares.avatar5(45, 45)}</IconButton>
          ) : avatarUsuario === "avatar6" ? (
            <IconButton>{Avatares.avatar6(45, 45)}</IconButton>
          ) : (
            <img
              className={classes.imgProfile}
              src={rutaAvatarDefault}
              alt="Imagen Usuario"
            />
          )}
        </DropdownToggle>
        <DropdownMenu align="end">
          <NavLink to="Perfil" className={classes.opciones}>
            <DropdownItem>
              <Person fontSize="small" className={classes.iconos} />
              Mi Perfil
            </DropdownItem>
          </NavLink>

          <DropdownItem className={classes.opciones} onClick={cambiarCompania}>
            <Business fontSize="small" className={classes.iconos} />
            Cambiar Compañía
          </DropdownItem>

          <DropdownItem className={classes.opciones} onClick={continuoDocs}>
            <Folder fontSize="small" className={classes.iconos} />
            Documentación
          </DropdownItem>

          <DropdownItem divider />

          <DropdownItem className={classes.opciones} onClick={SalirClick}>
            <PowerSettingsNew fontSize="small" className={classes.iconos} />
            Salir del Sistema
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Fragment>
  );
}
