import { Alert } from "@mui/material";
import React, { useState, useEffect } from "react";

const NoInternetConnection = () => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  return !isOnline ? (
    <Alert
      severity="error"
      sx={{
        position: "fixed",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        zIndex: 9999,
        border: "1px solid red",
      }}
    >
      Sin Conexión a Internet. Por favor revisar su conexión e intente otra vez.
    </Alert>
  ) : null;
};

export default NoInternetConnection;
