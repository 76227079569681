export const PRINT_LENTGH = {
  LETTER: 11,
  LEGAL: 14,
  EXECUTIVE: 7.25,
  FOLIO: 8.5,
  OFICIO: 13.5,
  TABLOIDE: 17,
  POSTAL: 5.8,
} as const;

// TODO: hay que revisar si el 0.33 es usado en todas las impresiones
export const PRINT_MARGIN = 0.4 + 0.33;

export const TIPO_NOMINA = {
  MENSUAL: 0,
  QUINCENAL: 1,
  SEMANAL: 2,
} as const;

export const TIPO_NOMINA_CANTIDAD_NOMINAS = {
  [TIPO_NOMINA.MENSUAL]: 1,
  [TIPO_NOMINA.QUINCENAL]: 2,
  [TIPO_NOMINA.SEMANAL]: 4,
} as const;

export const TIPO_NOMINA_LABEL = {
  [TIPO_NOMINA.MENSUAL]: "mensual",
  [TIPO_NOMINA.QUINCENAL]: "quincenal",
  [TIPO_NOMINA.SEMANAL]: "semanal",
};

export type TIPO_NOMINA_TYPE = (typeof TIPO_NOMINA)[keyof typeof TIPO_NOMINA];

export const TIPO_CUENTA_PAGO = {
  CT: "Cta. Corriente",
  AH: "Ahorro",
  OT: "Otra",
} as const;

export const WIDTH_PAGINA_85_11_PORTRAIT = "740px";
export const WIDTH_PAGINA_85_11_LANDSCAPE = "975px";
export const DEFUALT_PRINT_FONT_SIZE = "12px";


export const MONTO_CONSUMO = 250000