import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  Contenedor: {
    minHeight: "100%",
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
  },
  List: {
    padding: 5,
  },
  NavLink: {
    textDecoration: "none",
  },
  ListItem: {
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.5)!important",
    },
  },
  ListText: {
    color: "#fff",
    fontSize: "1rem",
    // "&:hover": {
    //   fontWeight: 800,
    // },
  },
  ListIcon: {
    color: "#fff!important",
    minWidth: "32px!important",
    // "&:hover": {
    //   fontWeight: 800,
    // },
  },
  SubmenuLink: {
    paddingLeft: "30px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  nivel1: {
    color: "#cecece",
    fontSize: "0.85rem",
    paddingLeft: "40px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  ListTextNivel1: {
    color: "#cecece",
    fontSize: "0.85rem",
    // "&:hover": {
    //   fontWeight: 800,
    // },
  },
  BorderMain: {
    borderTop: "1px dashed grey",
    borderBottom: "1px dashed grey",
  },
});
