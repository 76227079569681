import React, { useContext } from "react";
import { authContext } from "../../../MainComponent";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "../";
import { useQuery } from "react-query";
import { IContabilidadCatalogo } from "../../../../AppInterfaces";
import { Consultar } from "../../server/funcionesServidor";

type IDocumento = {
  cuentaId?: number;
  cuenta?: string;
  cuentaNombre?: string;
};

export function AutocompleteCuenta({
  documento,
  setDocumento,
  label = "Cta. Contable",
  required = false,
  disabled = false,
  error,
  isSoloAuxiliares = true,
  modificando = true,
  tiposCuenta = [],
  permiteLimpiar = false,
}: {
  documento: IDocumento;
  setDocumento: (documento: any) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  isSoloAuxiliares?: boolean;
  modificando?: boolean;
  tiposCuenta?: string[];
  permiteLimpiar?: boolean;
}) {
  const {
    companiaSeleccionada: { id: companiaId },
  } = useContext(authContext);

  const { data: catalogo = [] } = useQuery(
    "ContabilidadCatalogos",
    async () =>
      await Consultar<IContabilidadCatalogo>(
        "api/ContabilidadCatalogos/consultar",
        undefined,
        undefined,
        {
          where: `companiaId = ${companiaId}`,
        }
      )
  );

  return modificando ? (
    <Autocomplete
      disableClearable={!permiteLimpiar}
      options={
        catalogo
          .filter(
            (cuenta) =>
              !cuenta.isInactivo &&
              (!isSoloAuxiliares || !cuenta.isControl) &&
              (tiposCuenta.length !== 0
                ? tiposCuenta.some((v) => v === cuenta.cuenta.charAt(0))
                : true)
          )
          .map((cuenta) => ({
            firstLetter: cuenta.cuenta[0],
            cuentaId: cuenta.id,
            cuenta: cuenta.cuenta,
            cuentaNombre: cuenta.nombre,
          }))
          .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) ?? [
          {
            firstLetter: "",
            cuentaId: 0,
            cuenta: "",
            cuentaNombre: "",
          },
        ]
      }
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) =>
        option.cuenta ? option.cuenta + " - " + option.cuentaNombre : ""
      }
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          label={label}
          name="cuentaNombre"
          required={required}
          error={error}
        />
      )}
      onChange={(e, value) => {
        if (!permiteLimpiar && !value) return;

        const nuevoEstado = { ...documento };

        nuevoEstado.cuentaId = value?.cuentaId ?? 0;
        nuevoEstado.cuenta = value?.cuenta ?? "";
        nuevoEstado.cuentaNombre = value?.cuentaNombre ?? "";

        setDocumento(nuevoEstado);
      }}
      value={{
        firstLetter: documento.cuenta ? documento.cuenta[0] : "",
        cuentaId: documento.cuentaId ?? 0,
        cuenta: documento.cuenta ?? "",
        cuentaNombre: documento.cuentaNombre ?? "",
      }}
      disabled={disabled}
    />
  ) : (
    <div>
      <div>
        <strong>{label}</strong>
      </div>
      <div>
        {(documento.cuenta || "") + " - " + (documento.cuentaNombre || "")}
      </div>
    </div>
  );
}
