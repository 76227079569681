const tourPasos = [
    {
        selector: "#botonImprimir",
        content: "Puedes realizar una impresión del listado pulsando este botón.",
    },
    {
        selector: "#tablaBusqueda",
        content: "Aquí puedes buscar un valor en cualquier columna del listado.",
    },
    {
        selector: "#tablaColumnas",
        content: `Personaliza tu consulta o reporte quitando o añadiendo columnas con esta opción. 
                Esta configuración se guardará en esta computadora para un futuro uso.`,
    },
    {
        selector: "#tablaCVS",
        content: "Para exportar a CVS o PDF pulsa este botón.",
    },
    {
        selector: "#tablaCabeza",
        content:
            "Al pulsar cualquier cabezera los valores se ordenan según la columna seleccionada.",
    },
    {
        selector: "#tablaFiltros",
        content: "Puedes realizar filtros de varios campos en esta area.",
    },
    {
        selector: "#tablaData",
        content: "Para visualizar el registro deseado pulsa sobre el mismo.",
    },
    {
        selector: "#tablaBoton2",
        content: "Para ver la moneda y balance del cliente pulse este botón",
    },
];

export default tourPasos;
