import React, { useState } from "react";
import { AddCircle, DeleteForever } from "@material-ui/icons";
import Dialog from "../../../../../common/dialog/dialog";
import { TextField } from "../../../../../common/Inputs";

import {
  IBusqueda,
  IInventarioProducto,
  IOrdenServicioOrden,
  IOrdenServicioOrdenProducto,
  IOrdenServicioTecnicoLogin,
} from "../../../../../../AppInterfaces";

import {
  CardContent,
  Grid,
  Card,
  CardHeader,
  Table,
  TableRow,
  TableHead,
  TableCell,
  IconButton,
  TableBody,
  Button,
} from "@material-ui/core";
import MensajeAlerta from "../../../../../common/mensajeAlerta/mensajeAlerta";
import Busqueda from "../../../../../common/busqueda/busqueda";

export default function Productos({
  documento,
  setDocumento,
  setDocumentoModificado,
  productos,
  modificandoAgregandoDocumento,
  loginState,
}: {
  documento: IOrdenServicioOrden;
  setDocumento: (estado: IOrdenServicioOrden) => void;
  setDocumentoModificado: (estado: boolean) => void;
  productos: IInventarioProducto[];
  modificandoAgregandoDocumento: boolean;
  loginState: IOrdenServicioTecnicoLogin;
}) {
  const [lineaEliminar, setLineaEliminar] = useState<{
    registro?: IOrdenServicioOrdenProducto;
    indice?: number;
  }>();

  const [busquedaData, setBusquedaData] = useState<IBusqueda>({
    collection: "",
  });

  const agregarLinea = () => {
    const nuevoEstado: IOrdenServicioOrden = JSON.parse(
      JSON.stringify(documento)
    );
    nuevoEstado.productos.push({
      productoId: 0,
      cantidad: 0,
      productoNombre: "",
      productoReferencia: "",
      almacenId: loginState.almacenId,
      almacenNombre: loginState.nombre,
    });

    onBuscar(nuevoEstado.productos.length - 1, nuevoEstado)
  };

  const eliminarLinea = (index: number | undefined) => {
    const nuevoEstado: IOrdenServicioOrden = JSON.parse(
      JSON.stringify(documento)
    );

    nuevoEstado.productos[lineaEliminar?.indice ?? index ?? 0].eliminar = true;
    setDocumento(nuevoEstado);
    setDocumentoModificado(true);
    setLineaEliminar(undefined);
  };

  const onBuscarCerrar = (registro: IInventarioProducto) => {
    const nuevoEstado: IOrdenServicioOrden =
      JSON.parse(
        JSON.stringify(documento)
      );

    if (busquedaData.index || busquedaData.index === 0) {
      if (registro) {
        nuevoEstado.productos[
          busquedaData.index
        ].productoId = registro.id ?? 0;

        nuevoEstado.productos[
          busquedaData.index
        ].productoNombre =
          registro.nombre;

        nuevoEstado.productos[
          busquedaData.index
        ].productoReferencia =
          registro.referencia;

        setDocumento(nuevoEstado);
      } else {
        if (!nuevoEstado.productos[busquedaData.index].productoNombre)
          eliminarLinea(busquedaData.index);
      }
    }
    setBusquedaData({ collection: "" });
  }

  const onBuscar = (indice: number, nuevoEstado: IOrdenServicioOrden) => {
    if (nuevoEstado.productos[indice].almacenId === loginState.almacenId) {
      setBusquedaData({
        collection: 'InventarioProductos',
        index: indice,
        propiedad: undefined,
        where: undefined,
        order: undefined,
        listadoManual: productos
          .filter(
            (e) =>
              nuevoEstado.productos
                .filter(
                  (s) => s.eliminar === undefined
                )
                .some(
                  (d) => d.productoId === e.id
                ) === false
              && e.existencias.some((e) => nuevoEstado.productos[indice].almacenId === e.almacenId)
          ),
      });
    } else {
      MensajeAlerta("error", "No es posible modificar el producto de otro usuario.");
    }

    setDocumento(nuevoEstado);
    setDocumentoModificado(true);
  };

  return (
    <>
      {busquedaData.collection && (
        <Busqueda
          funcionCerrar={onBuscarCerrar}
          collection={busquedaData.collection}
          listadoManual={busquedaData.listadoManual}
        />
      )}
      {lineaEliminar &&
        lineaEliminar.registro &&
        lineaEliminar.indice !== undefined && (
          <Dialog
            open
            titulo="¡Advertencia!"
            estiloTitulo="Warning"
            mensaje={`¿Seguro desea eliminar el registro ${lineaEliminar.registro.productoNombre
              }, Fila: ${lineaEliminar.indice + 1}?`}
            textoBtn1="Continuar y Eliminar Registro"
            textoBtn2="Cancelar"
            accionDialogBtn1={() => eliminarLinea(undefined)}
            accionDialogBtn2={() => setLineaEliminar(undefined)}
          />
        )}

      <Grid item xs={12}>
        <Card className="card" id='cardProductos'>
          <CardHeader title="Productos" />
          <CardContent>
            {modificandoAgregandoDocumento ? (
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Acciones</TableCell>
                        <TableCell>Tecnico</TableCell>
                        <TableCell>Producto</TableCell>
                        <TableCell align="right">Cantidad</TableCell>
                      </TableRow>
                    </TableHead>
                    {documento?.productos ? (
                      <TableBody>
                        {documento.productos.map(
                          (registro, indice) =>
                            !registro.eliminar && (
                              <TableRow key={indice}>
                                <TableCell style={{ maxWidth: '10px', minWidth: '10px', width: '10px' }}>
                                  <IconButton
                                    onClick={() => {
                                      if (registro.almacenId === loginState.almacenId) {
                                        setLineaEliminar({ registro, indice })
                                      } else {
                                        MensajeAlerta("error", "No es posible eliminar un producto que no pertenezca al usuario");
                                      }
                                    }}
                                  >
                                    <DeleteForever />
                                  </IconButton>
                                </TableCell>

                                <TableCell style={{ maxWidth: '10px', minWidth: '10px', width: '10px' }}>
                                  {documento.productos[indice].almacenNombre}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    style={{ borderRadius: "10px" }}
                                    onClick={() => onBuscar(indice, documento)}
                                  >
                                    {registro.productoNombre}
                                  </Button>
                                </TableCell>
                                <TableCell style={{ width: '80px', maxWidth: '80px', minWidth: '80px' }}>
                                  <TextField
                                    disabled={registro.almacenId === loginState.almacenId ? false : true}
                                    name="cantidad"
                                    onChange={(e) => {
                                      const nuevoEstado: IOrdenServicioOrden =
                                        JSON.parse(JSON.stringify(documento));
                                      nuevoEstado.productos[indice].cantidad =
                                        +e.target.value;

                                      if (
                                        nuevoEstado.productos[indice].cantidad <
                                        0
                                      )
                                        nuevoEstado.productos[
                                          indice
                                        ].cantidad = 0;

                                      setDocumento(nuevoEstado);
                                    }}
                                    value={documento.productos[indice].cantidad}
                                    modificando={modificandoAgregandoDocumento}
                                    type="currency"
                                    required

                                  />
                                </TableCell>
                              </TableRow>
                            )
                        )}
                      </TableBody>
                    ) : null}
                  </Table>

                  <Button
                    fullWidth
                    id="botonMasDetalle"
                    onClick={() => agregarLinea()}
                  >
                    <AddCircle />
                    Agregar Linea
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tecnico</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell align="right">Cantidad</TableCell>
                      </TableRow>
                    </TableHead>
                    {documento?.productos ? (
                      <TableBody>
                        {documento.productos.map(
                          (registro, indice) =>
                            !registro.eliminar && (
                              <TableRow key={indice}>
                                <TableCell>
                                  <Grid item xs={12} id="productoNombre">
                                    {registro.almacenNombre}
                                  </Grid>
                                </TableCell>
                                <TableCell>
                                  <Grid item xs={12} id="productoNombre">
                                    {registro.productoNombre}
                                  </Grid>
                                </TableCell>
                                <TableCell align="right">
                                  <Grid item xs={12} id="cantidad">
                                    {registro.cantidad}
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            )
                        )}
                      </TableBody>
                    ) : null}
                  </Table>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
