import { ICuenta } from "../../../AppInterfaces";
import MensajeAlerta from "../mensajeAlerta/mensajeAlerta";

export const ValidarCustom = (
  nombreCampo: string = "",
  mensajeError: string = "",
  valor1: boolean = true,
  valor2: boolean = true,
  valor3: boolean = true,
  valor4: boolean = true
): any => {
  if (valor1 && valor2 && valor3 && valor4) {
    MensajeAlerta("error", mensajeError, false);
    return { [nombreCampo]: mensajeError };
  }
};

/**
 * Verifica que las cuentas contables segun proyecto esten cuadradas debitos y creditos
 */
export const validarCuentasContablesCuadradas = (
  nombreCampo: string,
  arregloCuentas: ICuenta[],
  mensajeError: string
): any => {
  let alertar = false;
  // No se valida por centro de costo
  // const centroCostos = [
  //   ...new Set(arregloCuentas.map((cuenta) => cuenta.centroCostoId)),
  // ];
  // const centroCostoProyectos = [
  //   ...new Set(arregloCuentas.map((cuenta) => cuenta.centroCostoProyectoId)),
  // ];
  // const centroCostoSubProyectos = [
  //   ...new Set(arregloCuentas.map((cuenta) => cuenta.centroCostoSubProyectoId)),
  // ];

  // for (let i = 0; i < centroCostos.length; i++) {
  //   const centroCosto = centroCostos[i];

  //   if (
  //     centroCosto !== 0 &&
  //     centroCosto !== undefined &&
  //     centroCosto !== null
  //   ) {
  //     const diferencia = +arregloCuentas
  //       .filter((cuenta) => cuenta.centroCostoId === centroCosto)
  //       .reduce((acc, val) => +(acc + +val.debito - +val.credito).toFixed(2), 0)
  //       .toFixed(2);

  //     if (diferencia !== 0) {
  //       const cuenta = arregloCuentas.find(
  //         (cuenta) => cuenta.centroCostoId === centroCosto
  //       );

  //       if (cuenta) {
  //         mensajeError += `Centro de Costo: ${cuenta.centroCostoNombre}, Diferencia: ${diferencia}`;
  //       }

  //       alertar = true;
  //     }
  //   }
  // }

  // for (let i = 0; i < centroCostoProyectos.length; i++) {
  //   const proyecto = centroCostoProyectos[i];

  //   if (proyecto !== 0 && proyecto !== undefined && proyecto !== null) {
  //     const diferencia = +arregloCuentas
  //       .filter((cuenta) => cuenta.centroCostoProyectoId === proyecto)
  //       .reduce((acc, val) => +(acc + +val.debito - +val.credito).toFixed(2), 0)
  //       .toFixed(2);

  //     if (diferencia !== 0) {
  //       const cuenta = arregloCuentas.find(
  //         (cuenta) => cuenta.centroCostoProyectoId === proyecto
  //       );

  //       if (cuenta) {
  //         mensajeError += `Centro de Costo Proyecto: ${cuenta.centroCostoProyectoNombre}, Diferencia: ${diferencia}`;
  //       }

  //       alertar = true;
  //     }
  //   }
  // }

  // for (let i = 0; i < centroCostoSubProyectos.length; i++) {
  //   const subproyeto = centroCostoSubProyectos[i];

  //   if (subproyeto !== 0 && subproyeto !== undefined && subproyeto !== null) {
  //     const diferencia = +arregloCuentas
  //       .filter((cuenta) => cuenta.centroCostoSubProyectoId === subproyeto)
  //       .reduce((acc, val) => +(acc + +val.debito - +val.credito).toFixed(2), 0)
  //       .toFixed(2);

  //     if (diferencia !== 0) {
  //       const cuenta = arregloCuentas.find(
  //         (cuenta) => cuenta.centroCostoSubProyectoId === subproyeto
  //       );

  //       if (cuenta) {
  //         mensajeError += `Centro de Costo Proyecto: ${cuenta.centroCostoSubProyectoNombre}, Diferencia: ${diferencia}`;
  //       }

  //       alertar = true;
  //     }
  //   }
  // }

  const diferencia = +arregloCuentas
    .reduce((acc, val) => {
      return +(
        acc +
        +(+val.debito).toFixed(2) -
        +(+val.credito).toFixed(2)
      ).toFixed(2);
    }, 0)
    .toFixed(2);

  if (diferencia !== 0) alertar = true;

  if (alertar) {
    MensajeAlerta("error", mensajeError, false);
    return { [nombreCampo]: mensajeError };
  } else return null;
};

/**
 * Verifica que la cuenta principal tenga el mismo valor que el total del documento
 */
export const validarCuentaPrincipalIgualTotal = (
  nombreCampo: string,
  cuentaPrincipalId: number,
  arregloCuentas: any[],
  total: number,
  mensajeError: string
): any => {
  if (
    +Math.abs(
      arregloCuentas
        .filter((cuenta) => cuenta.cuentaId === cuentaPrincipalId)
        .reduce((acc, val) => +(acc + +val.debito - +val.credito).toFixed(2), 0)
    ).toFixed(2) !== +Math.abs(+total).toFixed(2)
  ) {
    MensajeAlerta("error", mensajeError, false);
    return { [nombreCampo]: mensajeError };
  } else return null;
};

/**
 * Verifica que las cuentas de inventario tengan el mismo valor que el total del documento
 */
export const validarCuentaPrincipalIgualTotalInventario = (
  nombreCampo: string,
  arregloProductos: any[],
  arregloCuentas: any[],
  total: number,
  mensajeError: string
): any => {
  const cuentasInventario = [
    ...new Set(arregloProductos.map((producto) => producto.cuentaInventarioId)),
  ];

  if (
    +Math.abs(
      arregloCuentas
        .filter((cuenta) => cuentasInventario.includes(cuenta.cuentaId))
        .reduce((acc, val) => +(acc + +val.debito - +val.credito).toFixed(2), 0)
    ).toFixed(2) !== +Math.abs(+total).toFixed(2)
  ) {
    MensajeAlerta("error", mensajeError, false);
    return { [nombreCampo]: mensajeError };
  } else return null;
};

export const validarNumeroPositivo = (
  nombreCampo: string,
  valor: number,
  mensajeError: string
): any => {
  if (valor <= 0) {
    MensajeAlerta("error", mensajeError);
    return { [nombreCampo]: mensajeError };
  } else return null;
};

export const validarValorAsignado = (
  nombreCampo: string,
  valor: any,
  mensajeError: string
): any => {
  if (
    valor === null ||
    valor === undefined ||
    JSON.stringify(valor) === "[]" ||
    JSON.stringify(valor) === "{}" ||
    valor === "" ||
    valor === 0
  ) {
    MensajeAlerta("error", mensajeError, false);
    return { [nombreCampo]: mensajeError };
  } else return null;
};

export const validarValorEmail = (
  valor: string,
  nombreCampo: string,
  mensajeError: string
): any => {
  if (valor) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valor)) {
      MensajeAlerta("error", mensajeError, false);
      return { [nombreCampo]: mensajeError };
    }
  } else return null;
};

export const validarValorNumero = (
  valor: any,
  nombreCampo: string,
  mensajeError: string
): any => {
  if (isNaN(Number(valor))) {
    MensajeAlerta("error", mensajeError);
    return { [nombreCampo]: mensajeError };
  }
};

export const validarValorUnico = (
  dataOriginal: any[],
  dataNueva: any,
  nombreCampo: string,
  mensajeError: string
): any => {
  if (
    dataOriginal.some(
      (e) => e[nombreCampo] === dataNueva[nombreCampo] && e.id !== dataNueva.id
    )
  ) {
    MensajeAlerta("error", mensajeError, false);
    return { [nombreCampo]: mensajeError };
  } else return null;
};

export const validarNCFLongitud = (
  ncf?: string,
  nombreCampo: string = "ncf"
) => {
  if (ncf === "SIN NCF") return null;

  const longitudesValidas = [0, 19, 11, 13];
  const mensajeError = `El ncf solo admite longitudes de ${longitudesValidas.join()}.`;

  if (!!ncf) {
    if (!longitudesValidas.some((tam) => tam === ncf.length)) {
      MensajeAlerta("error", mensajeError, false);
      return { [nombreCampo]: mensajeError };
    }
  }

  return null;
};
