import React, { useState } from "react";

import { Button, Grid } from "@material-ui/core";

import Dialog from "../common/dialog/dialog";
import { TextField } from "../common/Inputs";
import MensajeAlerta from "../common/mensajeAlerta/mensajeAlerta";

import "../../styles/MontoCuadreCaja.css";

export default function MontoCuadreCaja({
  onConfirm,
}: {
  onConfirm: (monto: number) => void;
}) {
  const [confirmandoMonto, setConfirmandoMonto] = useState(false);
  const [monto, setMonto] = useState(0);

  const confirmarMonto = () => {
    if (!monto) {
      MensajeAlerta(
        "error",
        "Debe asignar un monto para inicio de caja.",
        false
      );
      return;
    }

    onConfirm(monto);
  };

  return (
    <>
      <Dialog
        open={confirmandoMonto}
        titulo="¡Advertencia!"
        estiloTitulo="Warning"
        mensaje={`¿Seguro desea iniciar el cuadre de caja con ${monto.toLocaleString(
          undefined,
          { maximumFractionDigits: 2, minimumFractionDigits: 2 }
        )}?`}
        textoBtn1="Confirmar Monto"
        textoBtn2="Cancelar"
        accionDialogBtn1={() => confirmarMonto()}
        accionDialogBtn2={() => setConfirmandoMonto(false)}
      />

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        id="montoCuadreCajaContainer"
        direction="column"
      >
        <Grid item>
          <TextField
            name="monto"
            autoFocus
            label="Monto Inicio Cuadre de Caja"
            onChange={(e) => setMonto(+e.target.value)}
            required
            type="currency"
            style={{ width: "250px" }}
          />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setConfirmandoMonto(true)}
          >
            Confirmar Monto
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
