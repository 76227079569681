import React from "react";
import Avatar from "avataaars";

export const avatar1 = (width: number, height: number) => <Avatar
    avatarStyle='Circle'
    topType='LongHairBigHair'
    accessoriesType='Blank'
    hairColor='BrownDark'
    facialHairType='Blank'
    clotheType='BlazerShirt'
    eyeType='Default'
    eyebrowType='Default'
    mouthType='Default'
    skinColor='Light'
    style={{ width, height }}
/>

export const avatar2 = (width: number, height: number) => <Avatar
    avatarStyle='Circle'
    topType='LongHairBob'
    accessoriesType='Blank'
    hairColor='BrownDark'
    facialHairType='Blank'
    clotheType='BlazerShirt'
    eyeType='Default'
    eyebrowType='Default'
    mouthType='Default'
    skinColor='Light'
    style={{ width, height }}
/>

export const avatar3 = (width: number, height: number) => <Avatar
    avatarStyle='Circle'
    topType='LongHairCurvy'
    accessoriesType='Blank'
    hairColor='BrownDark'
    facialHairType='Blank'
    clotheType='BlazerShirt'
    eyeType='Default'
    eyebrowType='Default'
    mouthType='Default'
    skinColor='Light'
    style={{ width, height }}
/>

export const avatar4 = (width: number, height: number) => <Avatar
    avatarStyle='Circle'
    topType='ShortHairShortFlat'
    accessoriesType='Blank'
    hairColor='BrownDark'
    facialHairType='Blank'
    clotheType='BlazerShirt'
    eyeType='Default'
    eyebrowType='Default'
    mouthType='Default'
    skinColor='Light'
    style={{ width, height }}
/>

export const avatar5 = (width: number, height: number) => <Avatar
    avatarStyle='Circle'
    topType='ShortHairShortRound'
    accessoriesType='Blank'
    hairColor='BrownDark'
    facialHairType='Blank'
    clotheType='BlazerShirt'
    eyeType='Default'
    eyebrowType='Default'
    mouthType='Default'
    skinColor='Light'
    style={{ width, height }}
/>

export const avatar6 = (width: number, height: number) => <Avatar
    avatarStyle='Circle'
    topType='ShortHairTheCaesar'
    accessoriesType='Blank'
    hairColor='BrownDark'
    facialHairType='Blank'
    clotheType='BlazerShirt'
    eyeType='Default'
    eyebrowType='Default'
    mouthType='Default'
    skinColor='Light'
    style={{ width, height }}
/> 