import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Typography } from "@mui/material";

export default function IdleAlert({
  getRemainingTime,
}: {
  getRemainingTime: () => number;
}) {
  const [remainingTime, setRemainingTime] = useState(30);
  const minutes = Math.floor(remainingTime / 60000);
  const seconds = +((remainingTime % 60000) / 1000).toFixed(0);

  useEffect(() => {
    if (getRemainingTime) {
      const intervalIdleTime = setInterval(() => {
        setRemainingTime(getRemainingTime());
      }, 1000);

      return () => clearInterval(intervalIdleTime);
    }
  }, [getRemainingTime]);

  return (
    <Modal
      isOpen={
        (minutes <= 4 && minutes > 0) || (minutes === 0 && seconds > 0)
          ? true
          : false
      }
      zIndex={20000}
    >
      <ModalBody className="modalBody">
        <Typography variant="h3" color="primary" gutterBottom>
          👋 Hola, estas ahí?
        </Typography>

        <Typography variant="h4" color="secondary" gutterBottom>
          Desconectandose en: ⌛
          {`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`}
        </Typography>
      </ModalBody>
    </Modal>
  );
}
