import React from "react";

import {
  CardContent,
  Grid,
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";

import {
  IAGlobalFinanciamientoFrecuenciaPago,
  IFinanciamientoPrestamo,
} from "../../../../../AppInterfaces";

import {
  FormatearNumero,
  calculaMontoPago,
} from "../../../../common/funciones/funciones";

import {
  AutocompleteCentroCosto,
  AutocompleteCentroCostoProyecto,
  AutocompleteCentroCostoSubProyecto,
  AutocompleteCliente,
  TextField,
} from "../../../../common/Inputs";

export default function Documento({
  documento,
  setDocumento,
  setDocumentoModificado,
  errores,
  roleNombre,
  frecuenciaPago,
  utilizaCentroCosto = false,
  sumarGastos = false,
}: {
  documento: IFinanciamientoPrestamo;
  setDocumento: (estado: IFinanciamientoPrestamo) => void;
  setDocumentoModificado: (estado: boolean) => void;
  errores: any;
  roleNombre: string;
  frecuenciaPago: IAGlobalFinanciamientoFrecuenciaPago[];
  utilizaCentroCosto: boolean;
  sumarGastos: boolean;
}) {
  const onInputChange = ({
    target: { name, type, value, checked },
  }: {
    target: { name: string; type: string; value: string; checked?: boolean };
  }) => {
    const nvalue =
      type === "checkbox"
        ? checked ?? false
        : type === "number" &&
          (value.length === 0 || value.length === 1 || +value !== 0)
        ? +(value || 0)
        : value;

    if (typeof nvalue === "number" && nvalue < 0) return;

    const nuevoEstado: IFinanciamientoPrestamo = JSON.parse(
      JSON.stringify({ ...documento, [name]: nvalue })
    );

    nuevoEstado.tasaInteresCuota =
      nuevoEstado.tasaInteresAnual / nuevoEstado.frecuencia;

    nuevoEstado.montoPago = calculaMontoPago(
      nuevoEstado.montoPrestamo +
        (sumarGastos
          ? +nuevoEstado.montoGastosCierre + +nuevoEstado.montoGastosLegales
          : 0),
      nuevoEstado.tasaInteresCuota,
      nuevoEstado.cantidadCuotas,
      nuevoEstado.tipoCalculo
    );

    if (
      nuevoEstado.montoPago &&
      nuevoEstado.cantidadCuotas &&
      nuevoEstado.montoPrestamo
    ) {
      nuevoEstado.montoInteres =
        nuevoEstado.montoPago * nuevoEstado.cantidadCuotas -
        (nuevoEstado.montoPrestamo +
          (sumarGastos
            ? +nuevoEstado.montoGastosCierre + +nuevoEstado.montoGastosLegales
            : 0));
    }

    nuevoEstado.total = sumarGastos
      ? +nuevoEstado.montoPrestamo +
        +nuevoEstado.montoInteres +
        +nuevoEstado.montoGastosCierre +
        +nuevoEstado.montoGastosLegales
      : +nuevoEstado.montoPrestamo +
        +nuevoEstado.montoInteres -
        +nuevoEstado.montoGastosCierre -
        +nuevoEstado.montoGastosLegales;

    nuevoEstado.montoDesembolsar = nuevoEstado.total - nuevoEstado.montoInteres;

    setDocumento(nuevoEstado);
    setDocumentoModificado(true);
  };

  const onFrecuenciaChange = ({
    target: { value },
  }: {
    target: { value: unknown };
  }) => {
    const { id: frecuenciaId = 0, nombre: frecuenciaNombre = "" } =
      frecuenciaPago.find(
        (frecuencia) => frecuencia.id === (value as number)
      ) ?? {};

    const nuevoEstado: IFinanciamientoPrestamo = JSON.parse(
      JSON.stringify({ ...documento, frecuenciaId, frecuenciaNombre })
    );

    setDocumento(nuevoEstado);
    setDocumentoModificado(true);
  };

  return (
    <Grid item xs={12}>
      <Card className="card">
        <CardHeader title="Edición de Prestamo" />

        <CardContent>
          <Grid container item spacing={1}>
            <Grid item xs={12} id="codigo">
              <TextField
                name="codigo"
                label="Código"
                value={documento.codigo}
                visible={!!documento.id}
                modificando={false}
                type="number"
              />
            </Grid>

            <Grid item xs={12} md={3} id="fecha">
              <TextField
                disabled={roleNombre === "operario"}
                required={!documento.fecha}
                name="fecha"
                label="Fecha"
                error={errores.fecha}
                onChange={onInputChange}
                value={documento.fecha}
                type="date"
              />
            </Grid>

            <Grid item xs={12} md={3} id="fechaInicio">
              <TextField
                required={!documento.fechaInicio}
                name="fechaInicio"
                label="Fecha Inicio"
                error={errores.fechaInicio}
                onChange={onInputChange}
                value={documento.fechaInicio}
                type="date"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel shrink id="tipoCalculoLabel">
                  Tipo de Saldo
                </InputLabel>
                <Select
                  labelId="tipoCalculoLabel"
                  label="Frecuencia por Defecto"
                  id="tipoCalculo"
                  name="tipoCalculo"
                  value={documento.tipoCalculo}
                  onChange={(e) => {
                    setDocumento({
                      ...documento,
                      tipoCalculo: +(e.target.value as number),
                    });
                    setDocumentoModificado(true);
                  }}
                >
                  <MenuItem value={0}>Insoluto</MenuItem>
                  <MenuItem value={1}>Soluto</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container item spacing={1}>
            <Grid item xs={12}>
              <AutocompleteCliente
                filter={(doc) => doc.id !== 0}
                required={!documento.clienteNombre}
                error={errores.clienteNombre}
                documentId={documento.clienteId}
                onChange={(doc) => {
                  if (!doc) {
                    return;
                  }

                  setDocumentoModificado(true);
                  setDocumento({
                    ...documento,
                    clienteId: doc.id ?? 0,
                    clienteNombre: doc.nombre || "",
                    clienteDestinatario: doc.nombre || "",
                    clienteRncCedula: doc.rncCedula || "",
                    clienteDireccion: doc.direccion || "",
                    clienteTelefono1: doc.telefono1 || "",
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                fullWidth
                required={!documento.frecuenciaNombre}
                variant="outlined"
                margin="normal"
              >
                <InputLabel shrink id="frecuenciaNombreLabel">
                  Frecuencia de pago
                </InputLabel>
                <Select
                  labelId="frecuenciaNombre"
                  label="Frecuencia de pago"
                  id="frecuenciaNombre"
                  name="frecuenciaNombre"
                  value={documento.frecuenciaId}
                  onChange={onFrecuenciaChange}
                >
                  {frecuenciaPago.map((frecuencia) => (
                    <MenuItem key={frecuencia.id} value={frecuencia.id}>
                      {frecuencia.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {utilizaCentroCosto && (
              <>
                <Grid item xs={12} sm={4} id="centroCostoNombre">
                  <AutocompleteCentroCosto
                    documento={documento}
                    setDocumento={setDocumento}
                  />
                </Grid>

                <Grid item xs={12} sm={4} id="centroCostoProyectoNombre">
                  <AutocompleteCentroCostoProyecto
                    documento={documento}
                    setDocumento={setDocumento}
                  />
                </Grid>

                <Grid item xs={12} sm={4} id="centroCostoSubProyectoNombre">
                  <AutocompleteCentroCostoSubProyecto
                    documento={documento}
                    setDocumento={setDocumento}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} id="observacion">
              <TextField
                name="observacion"
                label="Observación"
                error={errores.observacion}
                onChange={onInputChange}
                value={documento.observacion}
                rows={3}
                maxLength={8000}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={1}>
            <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="textSecondary" align="center" gutterBottom>
                    Valores del Prestamo
                  </Typography>

                  <Grid container item spacing={1}>
                    <Grid item xs={12} id="montoPrestamo">
                      <TextField
                        required={!documento.montoPrestamo}
                        name="montoPrestamo"
                        label="Monto del Prestamo"
                        error={errores.montoPrestamo}
                        onChange={onInputChange}
                        value={documento.montoPrestamo}
                        type="currency"
                      />
                    </Grid>

                    <Grid item xs={12} id="tasaInteresAnual">
                      <TextField
                        required={!documento.tasaInteresAnual}
                        name="tasaInteresAnual"
                        label="Tasa de Interes Anual"
                        error={errores.tasaInteresAnual}
                        onChange={onInputChange}
                        value={documento.tasaInteresAnual}
                        type="currency"
                      />
                    </Grid>

                    <Grid item xs={12} id="montoGastosCierre">
                      <TextField
                        name="montoGastosCierre"
                        label="Monto Gastos de Cierre"
                        error={errores.montoGastosCierre}
                        onChange={onInputChange}
                        value={documento.montoGastosCierre}
                        type="currency"
                      />
                    </Grid>

                    <Grid item xs={12} id="montoGastosLegales">
                      <TextField
                        name="montoGastosLegales"
                        label="Monto Gastos Legales"
                        error={errores.montoGastosLegales}
                        onChange={onInputChange}
                        value={documento.montoGastosLegales}
                        type="currency"
                      />
                    </Grid>

                    <Grid item xs={12} id="cantidadCuotas">
                      <TextField
                        required={!documento.cantidadCuotas}
                        name="cantidadCuotas"
                        label="Cantidad de Cuotas"
                        error={errores.cantidadCuotas}
                        onChange={onInputChange}
                        value={documento.cantidadCuotas}
                        type="currency"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="textSecondary" align="center" gutterBottom>
                    Resumen del Prestamo
                  </Typography>

                  <Grid container item spacing={1}>
                    <Grid item xs={12}>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ width: "250px" }}>
                              <Typography variant="h5">
                                Monto Solicitado.:
                              </Typography>
                            </td>
                            <td style={{ width: "150px" }} align="right">
                              <Typography variant="h5">
                                {FormatearNumero.format(
                                  documento.montoPrestamo
                                )}
                              </Typography>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ width: "250px" }}>
                              <Typography variant="h5">
                                Monto Total de Gastos de Cierre.:
                              </Typography>
                            </td>
                            <td style={{ width: "150px" }} align="right">
                              <Typography variant="h5">
                                {sumarGastos ? "+" : "-"}
                                {FormatearNumero.format(
                                  documento.montoGastosCierre
                                )}
                              </Typography>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ width: "250px" }}>
                              <Typography variant="h5">
                                Monto Total de Gastos Legales.:
                              </Typography>
                            </td>
                            <td
                              align="right"
                              style={{ width: "150px", borderBottom: "solid" }}
                            >
                              <Typography variant="h5">
                                {sumarGastos ? "+" : "-"}
                                {FormatearNumero.format(
                                  documento.montoGastosLegales
                                )}
                              </Typography>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ width: "250px" }}>
                              <Typography variant="h5" color="error">
                                <strong>Valor a Desembolsar.:</strong>
                              </Typography>
                            </td>
                            <td style={{ width: "150px" }} align="right">
                              <Typography variant="h5" color="error">
                                <strong>
                                  {FormatearNumero.format(
                                    documento.montoDesembolsar
                                  )}
                                </strong>
                              </Typography>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ width: "250px" }}>
                              <Typography variant="h5">
                                Monto Total de Intereses.:
                              </Typography>
                            </td>
                            <td
                              align="right"
                              style={{ width: "150px", borderBottom: "solid" }}
                            >
                              <Typography variant="h5">
                                +
                                {FormatearNumero.format(documento.montoInteres)}
                              </Typography>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ width: "250px" }}>
                              <Typography variant="h5" color="primary">
                                <strong>Valor total del prestamo.:</strong>
                              </Typography>
                            </td>
                            <td style={{ width: "150px" }} align="right">
                              <Typography variant="h5" color="primary">
                                <strong>
                                  {FormatearNumero.format(documento.total)}
                                </strong>
                              </Typography>
                            </td>
                          </tr>

                          <tr style={{ height: 100 }}>
                            <td style={{ width: "250px" }}>
                              <Typography variant="h5" color="secondary">
                                <strong>{`Pago ${documento.frecuenciaNombre}.:`}</strong>
                              </Typography>
                            </td>
                            <td style={{ width: "150px" }} align="right">
                              <Typography variant="h5" color="secondary">
                                <strong>
                                  {FormatearNumero.format(documento.montoPago)}
                                </strong>
                              </Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
