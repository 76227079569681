import React, { useContext } from "react";
import { authContext } from "../../../MainComponent";
import { Autocomplete } from "@mui/material";
import { TextField } from "../";
import { ICompaniaMoneda } from "../../../../AppInterfaces";

export function AutocompleteMoneda({
  documentId,
  onChange,
  name = "monedaNombre",
  label = "Moneda",
  required = false,
  disabled = false,
  modificando = true,
  error,
  permiteLimpiar = false,
}: {
  documentId?: number;
  onChange: (
    documento?: ICompaniaMoneda,
    documentosList?: ICompaniaMoneda[]
  ) => void;
  name?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  modificando?: boolean;
  error?: string;
  permiteLimpiar?: boolean;
}) {
  const {
    companiaSeleccionada: { monedas: registros },
  } = useContext(authContext);

  const registrosCopy = [...registros];

  const buscaDocumentoPorId = (docId?: number) =>
    registros.find((reg) => reg.id === docId);

  return modificando ? (
    <Autocomplete
      options={
        registrosCopy
          .sort((a, b) => -b.nombre[0].localeCompare(a.nombre[0]))
          .map((reg) => ({ docId: reg.id ?? 0 })) ?? [{ docId: 0 }]
      }
      getOptionLabel={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre;
        }

        return "";
      }}
      isOptionEqualToValue={(option, value) => option.docId === value.docId}
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          label={label}
          name={name}
          required={required}
          disabled={disabled}
          error={error}
        />
      )}
      onChange={(e, value) => {
        if (!permiteLimpiar && !value) return;

        onChange(buscaDocumentoPorId(value?.docId), registros);
      }}
      value={{ docId: documentId }}
      disabled={disabled}
      disableClearable={!permiteLimpiar}
    />
  ) : (
    <div>
      <div>
        <strong>Moneda</strong>
      </div>
      <div>{buscaDocumentoPorId(documentId)?.nombre}</div>
    </div>
  );
}
