import React, { useContext } from "react";

import { Autocomplete } from "@mui/material";
import { authContext } from "../../../MainComponent";

import { TextField } from "../";
import { ICxCClienteGrupo } from "../../../../AppInterfaces";

export function AutocompleteClienteGrupo({
  documentId,
  onChange,
  required = false,
  disabled = false,
  error,
  label = "Grupo de Clientes",
  mostrarInactivos,
  permiteLimpiar = false,
}: {
  documentId?: number;
  onChange: (
    documento?: ICxCClienteGrupo,
    documentosList?: ICxCClienteGrupo[]
  ) => void;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  label?: string;
  mostrarInactivos?: boolean;
  permiteLimpiar?: boolean;
}) {
  const {
    companiaSeleccionada: { CxCClienteGrupos: registros },
  } = useContext(authContext);

  const buscaDocumentoPorId = (docId?: number) =>
    registros.find((reg) => reg.id === docId);

  return (
    <Autocomplete
      options={
        registros
          .filter((reg) => (mostrarInactivos ? true : !reg.isInactivo))
          .sort((a, b) => -b.nombre[0].localeCompare(a.nombre[0]))
          .map((reg) => ({ docId: reg.id ?? 0 })) ?? [{ docId: 0 }]
      }
      groupBy={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre[0];
        }

        return "";
      }}
      getOptionLabel={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre;
        }

        return "";
      }}
      isOptionEqualToValue={(option, value) => option.docId === value.docId}
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          required={required}
          error={error}
          label={label}
          name="grupoNombre"
        />
      )}
      onChange={(e, value) => {
        if (!permiteLimpiar && !value) return;

        onChange(buscaDocumentoPorId(value?.docId), registros);
      }}
      value={{ docId: documentId }}
      disabled={disabled}
      disableClearable={!permiteLimpiar}
    />
  );
}
