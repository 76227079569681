const tourPasos = [
  {
    selector: "#botonModificarDocumento",
    content: "Para modificar el documento pulsa este botón.",
  },
  {
    selector: "#botonGuardar",
    content: "Para guardar el documento pulsa este botón.",
  },
  {
    selector: "#botonCancelar",
    content: "Para cancelar los cambios realizados pulsa este botón.",
  },
  {
    selector: "#codigo",
    content: "Aquí puedes visualizar el codigo de la orden",
  },
  {
    selector: "#fecha",
    content: "Aquí puedes visualizar la fecha de creación de la orden.",
  },
  {
    selector: "#fechaCompromiso",
    content: "Aquí puedes visualizar la fecha cuando se debe finalizar la orden.",
  },
  {
    selector: "#clienteNombre",
    content:
      "Aquí puedes visualizar el cliente que realiza la solicitud de la orden.",
  },
  {
    selector: "#localidadNombre",
    content:
      "Aquí puedes indicar la localidad del cliente donde se realiza la orden.",
  },
  {
    selector: "#tipoOrdenNombre",
    content: "Aquí puedes visualizar el tipo de la orden.",
  },
  {
    selector: "#observacion",
    content:
      "Aquí puedes visualizar una observación para identificar la razón de la orden.",
  },
  {
    selector: "#cardObservacionCierre",
    content:
      "Aquí puedes indicar el trabajo realizado en la orden",
  },
  {
    selector: "#cardProductos",
    content:
      "Aquí puedes indicar los productos usados en la orden. Estos productos serán descargados en el conduce de almacén.",
  },
  {
    selector: "#cardTecnicos",
    content: "Aquí puedes indicar los técnicos que realizarán la orden.",
  },
];

export default tourPasos;
