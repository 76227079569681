import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { authContext } from "../../../MainComponent";
import { IAuthContext } from "../../../../AppInterfaces";

export default function InformacionCompania({
  children,
  esEcf,
}: {
  children?: React.ReactNode;
  esEcf?: boolean;
}) {
  const {
    companiaSeleccionada: {
      logo: companiaLogo,
      nombre: companiaNombre,
      eMail,
      direccion,
      rnc,
      telefono,
      CxCConfiguracion: { logoCompaniaWidth, logoCompaniaHeight },
    },
  } = useContext(authContext) as IAuthContext;

  return (
    <Grid container item spacing={1}>
      <Grid item xs={companiaLogo ? undefined : 12}>
        {companiaLogo ? (
          <img
            src={"data:image/png;base64," + companiaLogo}
            alt="Logo Compania"
            width={logoCompaniaWidth}
            height={logoCompaniaHeight}
          />
        ) : (
          <strong style={{ fontSize: "2rem" }}>{companiaNombre}</strong>
        )}
      </Grid>

      <Grid item xs={6} sx={{ wordWrap: "break-word" }}>
        {esEcf && companiaLogo ? companiaNombre : null}
        {rnc ? <div>RNC: {rnc}</div> : null}
        {direccion ? <div>Dirección: {direccion}</div> : null}
        {telefono ? <div>Teléfono: {telefono}</div> : null}
        {eMail ? <div>E-Mail: {eMail}</div> : null}
        {children}
      </Grid>
    </Grid>
  );
}
