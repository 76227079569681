import React, { useState } from "react";
import classes from "./Resetearcontrasena.module.css";
import { required, validEmail } from "../../../common/Validadores/Validadores";
import {
  Button,
  TextField,
  Paper,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { Ejecutar } from "../../../common/server/funcionesServidor";
import ModalResetearContrasena from "./ModalResetearContrasena";

export default function ResetearContrasena({
  toggleResetearContrasena,
}: {
  toggleResetearContrasena: () => void;
}) {
  const [email, setEmail] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [reiniciarModal, setReiniciarModal] = useState(false);

  /**envia solicitud de resetear contraseña*/
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    Ejecutar(
      "api/OrdenServicioTecnicos/resetpassword",
      null,
      { email: email },
      "Correo enviado. El link solo es valido por 24 horas.",
      undefined,
      true
    );
  };

  /**valida que el email introducido sea valido*/
  const handleInputChange = ({
    target: { name, type, value, checked },
  }: {
    target: { name: string; type: string; value: string; checked?: boolean };
  }) => {
    const nvalue =
      type === "checkbox"
        ? checked ?? false
        : type === "number" &&
          (value.length === 0 || value.length === 1 || +value !== 0)
        ? +(value || 0)
        : value;

    if (typeof nvalue === "number" && nvalue < 0) return;

    if (name === "email" && typeof nvalue === "string") setEmail(nvalue);

    let mensaje = "";
    if (name === "email" && typeof nvalue === "string") {
      if (!validEmail(nvalue)) {
        mensaje = "Email no valido";
      }
      if (!required(nvalue)) {
        mensaje = "Email requerido";
      }
      setErrEmail(mensaje);
    }
  };

  const onCerrarAbrirModal = () => {
    setReiniciarModal(!reiniciarModal);
  };

  return (
    <>
      <div className={classes.titulo}>
        <Typography component="h1" variant="h3">
          Reiniciar contraseña
        </Typography>
        <Typography component="h2" variant="h5">
          Por favor introduzca su email
        </Typography>
      </div>

      {reiniciarModal ? (
        <ModalResetearContrasena
          onCerrarAbrirModal={onCerrarAbrirModal}
          toggleResetearContrasena={toggleResetearContrasena}
        />
      ) : null}

      <Paper className={["colorFondo", classes.contenedorInterno].join(" ")}>
        <form onSubmit={handleSubmit}>
          <Grid container item spacing={1} direction="column">
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <AccountCircle />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  className="inputs"
                  required
                  autoFocus
                  fullWidth
                  error={errEmail === "" ? false : true}
                  helperText={errEmail}
                  name="email"
                  label="Email"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  onChange={handleInputChange}
                  value={email}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              justifyContent="center"
              style={{ paddingBottom: "8px" }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={["boton", classes.submitbutton].join(" ")}
              >
                Reiniciar Contraseña
              </Button>
            </Grid>
          </Grid>
        </form>

        <Grid container item justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={onCerrarAbrirModal}
            className={["boton", classes.submitbutton].join(" ")}
          >
            Tengo un codigo
          </Button>
        </Grid>

        <Link
          className="enlace"
          component="button"
          variant="body2"
          onClick={() => toggleResetearContrasena()}
        >
          Iniciar Sesión
        </Link>
      </Paper>
    </>
  );
}
