import React, { useContext } from "react";
import { authContext } from "../../../MainComponent";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "../";
import { ITallerMecanicaAreaEstadosServicio } from "../../../../AppInterfaces";
import { useQuery } from "react-query";
import { Consultar } from "../../server/funcionesServidor";

type IDocumento = {
  estadoId?: number;
  estadoNombre?: string;
  estadoOrden?: number;
};

export function AutocompleteTallerMecanicaAreaEstadosServicio({
  documento,
  setDocumento,
  areaId,
  label = "Estado",
  required = false,
  disabled = false,
  error,
  modificando = true,
}: {
  documento: IDocumento;
  setDocumento: (documento: any) => void;
  areaId: number;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  modificando?: boolean;
}) {
  const {
    companiaSeleccionada: { id: companiaId },
  } = useContext(authContext);

  const { data: estadosServicios } = useQuery(
    "TallerMecanicaAreaEstadosServicios",
    async () =>
      await Consultar<ITallerMecanicaAreaEstadosServicio>(
        `api/TallerMecanicaAreaEstadosServicios/consultar`,
        undefined,
        undefined,
        {
          where: `companiaId = ${companiaId} and isInactivo = 0`,
          order: "orden asc",
        }
      )
  );

  return modificando ? (
    <Autocomplete
      options={
        estadosServicios
          ?.filter((tipo) => tipo.areaId === areaId)
          ?.map((estado) => ({
            estadoId: estado.id ?? 0,
            estadoNombre: estado.nombre,
            orden: estado.orden,
          })) ?? [
          {
            estadoId: 0,
            estadoNombre: "",
            orden: 0,
          },
        ]
      }
      getOptionLabel={(option) => option.estadoNombre}
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          required={required}
          name="estadoNombre"
          label={label}
          error={error}
        />
      )}
      onChange={(event, value) => {
        if (value) {
          setDocumento({
            ...documento,
            estadoId: value.estadoId,
            estadoNombre: value.estadoNombre,
            estadoOrden: value.orden,
          });
        }
      }}
      value={{
        estadoId: documento.estadoId ?? 0,
        estadoNombre: documento.estadoNombre ?? "",
        orden: documento.estadoOrden ?? 0,
      }}
      disabled={disabled}
    />
  ) : (
    <div>
      <div>
        <strong>{label}</strong>
      </div>
      <div>{documento.estadoNombre || ""}</div>
    </div>
  );
}
