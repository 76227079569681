import React, { useContext } from "react";
import { authContext } from "../../../MainComponent";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "../";

interface IDocumento {
  departamentoId?: number;
  departamentoNombre?: string;
}

export function AutocompleteNominaDepartamento({
  documento,
  setDocumento,
  modificando = true,
  required = false,
  disabled = false,
  error,
}: {
  documento: IDocumento;
  setDocumento: (documento: any) => void;
  modificando?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: string;
}) {
  const {
    companiaSeleccionada: { nominaDepartamentos },
  } = useContext(authContext);

  return modificando ? (
    <Autocomplete
      options={
        nominaDepartamentos
          .filter((reg) => !reg.isInactivo)
          .map((reg) => ({
            firstLetter: reg.nombre[0],
            id: reg.id,
            nombre: reg.nombre,
          }))
          .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) ?? [
          {
            firstLetter: "",
            id: 0,
            nombre: "",
          },
        ]
      }
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => (option.nombre ? option.nombre : "")}
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          label="Departamento"
          name="departamentoNombre"
          required={required}
          disabled={disabled}
          error={error}
        />
      )}
      onChange={(e, value) => {
        const nuevoEstado = { ...documento };

        nuevoEstado.departamentoId = value?.id || 0;
        nuevoEstado.departamentoNombre = value?.nombre || "";

        setDocumento(nuevoEstado);
      }}
      value={{
        firstLetter: documento.departamentoNombre
          ? documento.departamentoNombre[0]
          : "",
        id: documento.departamentoId || 0,
        nombre: documento.departamentoNombre || "",
      }}
      disabled={disabled}
    />
  ) : (
    <div>
      <div>
        <strong>Departamento</strong>
      </div>
      <div>{documento.departamentoNombre}</div>
    </div>
  );
}
