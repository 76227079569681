import React, { useContext } from "react";
import { authContext } from "../../../MainComponent";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "../";

interface IDocumento {
  supAFPId?: number;
  supAFPNombre?: string;
}

export function AutocompleteRRHHSupAFP({
  documento,
  setDocumento,
  modificando = true,
  required = false,
  disabled = false,
  error,
}: {
  documento: IDocumento;
  setDocumento: (documento: any) => void;
  modificando?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: string;
}) {
  const { RRHHSupAFP } = useContext(authContext);

  return modificando ? (
    <Autocomplete
      options={
        RRHHSupAFP.filter((reg) => !reg.isInactivo)
          .map((reg) => ({
            firstLetter: reg.nombre[0],
            id: reg.id,
            nombre: reg.nombre,
          }))
          .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) ?? [
          {
            firstLetter: "",
            id: 0,
            nombre: "",
          },
        ]
      }
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => (option.nombre ? option.nombre : "")}
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          label="Suplidor AFP"
          name="supAFPNombre"
          required={required}
          disabled={disabled}
          error={error}
        />
      )}
      onChange={(e, value) => {
        const nuevoEstado = { ...documento };

        nuevoEstado.supAFPId = value?.id || 0;
        nuevoEstado.supAFPNombre = value?.nombre || "";

        setDocumento(nuevoEstado);
      }}
      value={{
        firstLetter: documento.supAFPNombre ? documento.supAFPNombre[0] : "",
        id: documento.supAFPId || 0,
        nombre: documento.supAFPNombre || "",
      }}
      disabled={disabled}
    />
  ) : (
    <div>
      <div>
        <strong>Suplidor AFP</strong>
      </div>
      <div>{documento.supAFPNombre}</div>
    </div>
  );
}
