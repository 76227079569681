import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Grid, Tooltip } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { useReactToPrint } from "react-to-print";
import Tour from "reactour";

import {
  IAuthContext,
  IFinanciamientoPrestamo,
} from "../../../../../AppInterfaces";

import {
  GrabarCustom,
  EliminarCustom,
} from "../../../../common/server/funcionesServidor";
import {
  arregloCuotasPrestamo,
  FormatearFecha,
} from "../../../../common/funciones/funciones";
import BarraHerramienta from "../../../../common/barraHerramienta/barraHerramienta";
import Dialog from "../../../../common/dialog/dialog";
import { authContext } from "../../../../MainComponent";
import { validarValorAsignado } from "../../../../common/validaciones/validaciones";

import tourPasos from "./Tour";
import Documento from "./Documento";
import Detalle from "./Detalle";
import Impresion from "./Impresion";
import MensajeAlerta from "../../../../common/mensajeAlerta/mensajeAlerta";

export default function Editor({
  documentoEditar,
  onCerrar,
  initModificandoAgregando = false,
}: {
  documentoEditar: IFinanciamientoPrestamo;
  onCerrar: (estado?: IFinanciamientoPrestamo) => void;
  initModificandoAgregando?: boolean;
}) {
  const collection = "FinanciamientoPrestamos";

  const {
    companiaSeleccionada: {
      id: companiaId,
      financiamientoConfiguracion,
      utilizaCentroCosto,
      monedas,
      centroCostos,
    },
    username,
    roleNombre,
    frecuenciaPago,
  } = useContext(authContext) as IAuthContext;

  const [isTourAbierto, setIsTourAbierto] = useState(false);
  const [modificandoAgregandoDocumento, setModificandoAgregandoDocumento] =
    useState(false);
  const [confirmarAccionCancelar, setConfirmarAccionCancelar] = useState(false);
  const [confirmarAccionEliminar, setConfirmarAccionEliminar] = useState(false);
  const [documentoModificado, setDocumentoModificado] = useState(false);
  const [errores, setErrores] = useState({});
  const [documento, setDocumento] = useState(documentoEditar);
  const [isGrabando, setIsGrabando] = useState(false);

  /**Impresión**/
  const printRef = useRef(null);
  const onImprimir = useReactToPrint({
    content: () => printRef.current,
    pageStyle: "@page { size: 8.5in 5.5in portrait; margin: 0.4in; }",
  });
  /**Impresión**/

  /**Asigna el documento a editar con infomacion de evento */
  const onAsignarDocumento = useCallback(
    (documentoNuevo: IFinanciamientoPrestamo) => {
      if (documentoNuevo.evento) delete documentoNuevo.evento;
      if (documentoNuevo.tableData) delete documentoNuevo.tableData;

      setDocumento({
        ...documentoNuevo,
        evento: {
          usuario: username,
          estadoAnterior: documentoNuevo.id
            ? JSON.stringify(documentoNuevo)
            : undefined,
        },
      });
    },
    [username]
  );

  useEffect(() => {
    onAsignarDocumento(documentoEditar);
    setModificandoAgregandoDocumento(initModificandoAgregando);
  }, [documentoEditar, initModificandoAgregando, onAsignarDocumento]);

  const toogleTour = () => setIsTourAbierto((isTourAbierto) => !isTourAbierto);

  //Acciones de la barra de herramientas
  const onNuevo = () => {
    onAsignarDocumento({
      companiaId: companiaId ?? 0,
      monedaId: monedas[0].id ?? 0,
      clienteId: 0,
      frecuenciaId: financiamientoConfiguracion.frecuenciaId ?? 0,
      centroCostoId: centroCostos[0].id ?? 0,
      tipoCalculo: 0,
      fecha: FormatearFecha(new Date(), "YYYY-MM-DD"),
      fechaInicio: FormatearFecha(new Date(), "YYYY-MM-DD"),
      montoPrestamo: 0,
      montoGastosCierre: 0,
      montoGastosLegales: 0,
      montoDesembolsar: 0,
      montoInteres: 0,
      total: 0,
      montoPago: 0,
      tasaInteresAnual: 0,
      tasaInteresCuota: 0,
      cantidadCuotas: 0,
      isPagado: false,
      clienteNombre: "",
      clienteRncCedula: "",
      clienteDestinatario: "",
      frecuenciaNombre: financiamientoConfiguracion.frecuenciaNombre,
      frecuencia: financiamientoConfiguracion.frecuencia,
      isAutorizado: false,
      isRechazado: false,
      centroCostoNombre: centroCostos[0].nombre,
      cuotas: [],
    });

    setModificandoAgregandoDocumento(true);
  };

  const onModificar = () => {
    if (documento.isAutorizado || documento.isRechazado) {
      MensajeAlerta(
        "error",
        "Este prestamo ya ha sido aprovado, no se puede modificar. Para realizar modificaciones se debe habilitar para modificación en la opción de aprovar prestamos.",
        false
      );

      return;
    }

    setModificandoAgregandoDocumento(true);
    onAsignarDocumento(documento);
  };

  const onEliminarConfirmar = () => setConfirmarAccionEliminar(true);
  const onEliminar = async () => {
    if (documento.evento) delete documento.evento;
    if (documento.tableData) delete documento.tableData;

    let respuesta = await EliminarCustom(`api/${collection}/eliminar`, {
      ...documento,
      evento: { usuario: username, estadoAnterior: JSON.stringify(documento) },
    });

    if (respuesta.length) {
      onCerrar();
    } else setConfirmarAccionEliminar(false);
  };
  const onEliminarCancelar = () => setConfirmarAccionEliminar(false);

  const onCancelarConfirmar = () =>
    documentoModificado ? setConfirmarAccionCancelar(true) : onCancelar();
  const onCancelar = () => onCerrar(documento);
  const onCancelarCancelar = () => setConfirmarAccionCancelar(false);

  const insertaDetalle = useCallback(
    (nuevoEstado: IFinanciamientoPrestamo) => {
      //Limpia las cuotas para reinsertarlos
      nuevoEstado.cuotas = nuevoEstado.cuotas.filter((cuota) => cuota.id);
      nuevoEstado.cuotas.forEach((cuota) => (cuota.eliminar = true));

      const {
        cantidadCuotas,
        montoPago,
        tasaInteresCuota,
        montoPrestamo,
        fechaInicio,
        tipoCalculo,
        frecuenciaId,
        montoGastosCierre,
        montoGastosLegales,
      } = nuevoEstado;

      nuevoEstado.cuotas = arregloCuotasPrestamo(
        cantidadCuotas,
        montoPago,
        tasaInteresCuota,
        montoPrestamo +
          (financiamientoConfiguracion.sumarGastos
            ? +montoGastosCierre + +montoGastosLegales
            : 0),
        fechaInicio,
        tipoCalculo,
        frecuenciaId
      );
    },
    [financiamientoConfiguracion.sumarGastos]
  );

  const onGrabar = useCallback(async () => {
    //Validaciones
    let error = {
      ...validarValorAsignado(
        "clienteNombre",
        documento.clienteNombre,
        "Debe indicar un cliente para el prestamo para proceder."
      ),
      ...validarValorAsignado(
        "montoPrestamo",
        documento.montoPrestamo,
        "Debe indicar un monto para el prestamo para proceder."
      ),
      ...validarValorAsignado(
        "tasaInteresAnual",
        documento.tasaInteresAnual,
        "Debe indicar una tasa anual para proceder."
      ),
      ...validarValorAsignado(
        "cantidadCuotas",
        documento.cantidadCuotas,
        "Debe indicar una cantidad de cuotas para proceder."
      ),
    };

    error = {
      ...error,
      ...validarValorAsignado(
        "centroCostoNombre",
        documento.centroCostoNombre,
        "Debe indicar un centro de costo para proceder."
      ),
    };

    if (JSON.stringify(error) !== "{}") {
      setErrores(error);
      setIsGrabando(false);
      return;
    }
    //Validaciones

    const nuevoEstado: IFinanciamientoPrestamo = JSON.parse(
      JSON.stringify(documento)
    );

    insertaDetalle(nuevoEstado);

    const nuevoDoc: IFinanciamientoPrestamo[] = await GrabarCustom(
      `api/${collection}/crearModificar`,
      nuevoEstado
    );

    setIsGrabando(false);

    if (nuevoDoc.length) {
      setDocumento(nuevoDoc[0]);
      setDocumentoModificado(false);
      setModificandoAgregandoDocumento(false);
    }
  }, [documento, insertaDetalle]);
  //Acciones de la barra de herramientas

  useEffect(() => {
    if (isGrabando) onGrabar();
  }, [isGrabando, onGrabar]);

  const onCalcularCuotas = () => {
    if (
      documento.montoPrestamo &&
      documento.tasaInteresAnual &&
      documento.cantidadCuotas
    ) {
      const nuevoEstado: IFinanciamientoPrestamo = JSON.parse(
        JSON.stringify(documento)
      );

      insertaDetalle(nuevoEstado);
      setDocumento(nuevoEstado);
    }
  };

  return (
    <>
      <Tooltip style={{ zIndex: 2000 }} title="Tour" aria-label="Tour">
        <Help
          className="botonTour"
          onClick={toogleTour}
          fontSize="large"
          id="botonTour"
        />
      </Tooltip>
      <Tour
        steps={tourPasos}
        isOpen={isTourAbierto}
        onRequestClose={toogleTour}
      />

      <BarraHerramienta
        accionGrabar={() => setIsGrabando(true)}
        accionCancelar={onCancelarConfirmar}
        accionNuevo={onNuevo}
        accionModificar={onModificar}
        accionEliminar={onEliminarConfirmar}
        accionImprimir={onImprimir}
        accionRefrescar={onCalcularCuotas}
        botonRefrescarTexto="Calcular Cuotas"
        botonRefrescarDesHabilitar={!modificandoAgregandoDocumento}
        botonNuevoDesHabilitar={modificandoAgregandoDocumento}
        botonModificarDesHabilitar={modificandoAgregandoDocumento}
        botonEliminarDesHabilitar={modificandoAgregandoDocumento}
        botonGrabarDesHabilitar={!modificandoAgregandoDocumento}
        botonImprimirDesHabilitar={modificandoAgregandoDocumento}
        isGrabando={isGrabando}
      />

      <Dialog
        open={confirmarAccionCancelar}
        titulo="¡Advertencia!"
        estiloTitulo="Warning"
        mensaje="¿Seguro desea cancelar el proceso?, Existen cambios sin grabar si procede serán descartados."
        textoBtn1="Continuar y Descartar Cambios"
        textoBtn2="Cancelar"
        accionDialogBtn1={onCancelar}
        accionDialogBtn2={onCancelarCancelar}
      />

      <Dialog
        open={confirmarAccionEliminar}
        titulo="¡Advertencia!"
        estiloTitulo="Warning"
        mensaje="¿Seguro desea eliminar el registro completo?"
        textoBtn1="Continuar y Eliminar Registro"
        textoBtn2="Cancelar"
        accionDialogBtn1={onEliminar}
        accionDialogBtn2={onEliminarCancelar}
      />

      {modificandoAgregandoDocumento ? (
        <Grid container item spacing={1}>
          <Documento
            documento={documento}
            setDocumento={setDocumento}
            setDocumentoModificado={setDocumentoModificado}
            errores={errores}
            roleNombre={roleNombre}
            frecuenciaPago={frecuenciaPago}
            utilizaCentroCosto={utilizaCentroCosto}
            sumarGastos={financiamientoConfiguracion.sumarGastos}
          />

          <Detalle documento={documento} />
        </Grid>
      ) : (
        <Grid container item ref={printRef} className="imprimirListado">
          <Impresion documento={documento} />
        </Grid>
      )}

      <BarraHerramienta
        accionGrabar={() => setIsGrabando(true)}
        accionCancelar={onCancelarConfirmar}
        accionNuevo={onNuevo}
        accionModificar={onModificar}
        accionEliminar={onEliminarConfirmar}
        accionImprimir={onImprimir}
        accionRefrescar={onCalcularCuotas}
        botonRefrescarTexto="Calcular Cuotas"
        botonRefrescarDesHabilitar={!modificandoAgregandoDocumento}
        botonNuevoDesHabilitar={modificandoAgregandoDocumento}
        botonModificarDesHabilitar={modificandoAgregandoDocumento}
        botonEliminarDesHabilitar={modificandoAgregandoDocumento}
        botonGrabarDesHabilitar={!modificandoAgregandoDocumento}
        botonImprimirDesHabilitar={modificandoAgregandoDocumento}
        isGrabando={isGrabando}
      />
    </>
  );
}

export const frecuenciaDias: Record<string, number> = {
  f8: 7,
  f7: 14,
  f6: 15,
  f5: 30,
  f4: 60,
  f3: 90,
  f2: 180,
  f1: 365,
};
