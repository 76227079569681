import React, { useContext } from "react";
import { authContext } from "../../../MainComponent";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "../";
import { useQuery } from "react-query";
import { Consultar } from "../../server/funcionesServidor";
import { ICxPSuplidor } from "../../../../AppInterfaces";

type IDocumento = {
  suplidorId?: number;
  suplidorRncCedula?: string;
  suplidorNombre?: string;
  suplidorDireccion?: string;
  destinatario?: string;
  condicionPago?: number;
  isInformal?: boolean;
  isGastoMenor?: boolean;
  isPagoExterior?: boolean;
  grupoCuentaCxPId?: number;
  grupoCuentaCxPNombre?: string;
  grupoCuentaCxP?: string;
};

export function AutocompleteSuplidor({
  required = false,
  disabled = false,
  error,
  documento,
  setDocumento,
}: {
  required?: boolean;
  disabled?: boolean;
  error?: string;
  documento: IDocumento;
  setDocumento: (documento: IDocumento) => void;
}) {
  const {
    companiaSeleccionada: { id: companiaId },
  } = useContext(authContext);

  const { data = [] } = useQuery(
    "CxPSuplidors",
    async () =>
      await Consultar<ICxPSuplidor>(
        "api/CxPSuplidors/consultarBalance",
        undefined,
        undefined,
        { companiaId }
      )
  );

  return (
    <Autocomplete
      options={
        data
          .filter((reg) => !reg.isInactivo)
          .map((reg) => ({
            firstLetter: reg.nombre[0],
            id: reg.id,
            rncCedula: reg.rncCedula,
            nombre: reg.nombre,
          }))
          .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) ?? [
          {
            firstLetter: "",
            id: 0,
            rncCedula: "",
            nombre: "",
          },
        ]
      }
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) =>
        option.rncCedula ? option.rncCedula + " - " + option.nombre : ""
      }
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          label="Suplidor"
          name="suplidorNombre"
          required={required}
          disabled={disabled}
          error={error}
        />
      )}
      onChange={(e, value) => {
        const suplidor = data.find((suplidor) => suplidor.id === value?.id);

        const nuevoEstado = { ...documento };

        nuevoEstado.suplidorId = value?.id || 0;
        nuevoEstado.suplidorRncCedula = value?.rncCedula || "";
        nuevoEstado.suplidorNombre = value?.nombre || "";
        nuevoEstado.suplidorDireccion = suplidor?.direccion || "";
        nuevoEstado.destinatario = value?.nombre || "";
        nuevoEstado.condicionPago = suplidor?.condicionPago || 0;
        nuevoEstado.isInformal = suplidor?.isInformal || false;
        nuevoEstado.isGastoMenor = suplidor?.isGastoMenor || false;
        nuevoEstado.isPagoExterior = suplidor?.isPagoExterior || false;
        nuevoEstado.grupoCuentaCxPId = suplidor?.grupoCuentaCxPId;
        nuevoEstado.grupoCuentaCxPNombre = suplidor?.grupoCuentaCxPNombre;
        nuevoEstado.grupoCuentaCxP = suplidor?.grupoCuentaCxP;

        setDocumento(nuevoEstado);
      }}
      value={{
        firstLetter: documento.suplidorNombre
          ? documento.suplidorNombre[0]
          : "",
        id: documento.suplidorId || 0,
        rncCedula: documento.suplidorRncCedula || "",
        nombre: documento.suplidorNombre || "",
      }}
      disabled={disabled}
    />
  );
}
