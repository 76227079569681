import React, { useContext } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import { IFinanciamientoPrestamo } from "../../../../../AppInterfaces";

import {
  FormatearNumero,
  convertirTextoAFecha,
  FormatearFecha,
  tamañoImpresionLetter,
} from "../../../../common/funciones/funciones";
import CabezeraInforme from "../../../../common/cabezeraInforme/cabezeraInforme";
import InformacionCompania from "../../../../common/Impresion/InformacionCompania/InformacionCompania";
import { authContext } from "../../../../MainComponent";

export default function Impresion({
  documento,
}: {
  documento: IFinanciamientoPrestamo;
}) {
  const {
    companiaSeleccionada: {
      utilizaCentroCosto,
      financiamientoConfiguracion: { sumarGastos },
    },
  } = useContext(authContext);

  return (
    <Paper
      elevation={0}
      square
      style={{
        minHeight: `${tamañoImpresionLetter(documento.cuotas.length)}in`,
        flexGrow: 1,
      }}
      className="imprimirDocumento"
    >
      <div>
        <CabezeraInforme noUsarNombreCompania={true} />

        {/* Titulo */}
        <Grid container item spacing={1}>
          <Grid item xs={8}>
            <InformacionCompania />
          </Grid>

          <Grid item xs={4}>
            <Grid item>
              <strong style={{ color: "red" }}>
                Prestamo No.: {documento.codigo}
              </strong>
            </Grid>

            <Grid item>
              <strong>
                Fecha.:{" "}
                {FormatearFecha(
                  convertirTextoAFecha(documento.fecha),
                  "DD-MM-YYYY"
                )}
              </strong>
            </Grid>

            <Grid item>
              <strong>
                Fecha de Inicio.:{" "}
                {FormatearFecha(
                  convertirTextoAFecha(documento.fechaInicio),
                  "DD-MM-YYYY"
                )}
              </strong>
            </Grid>

            {utilizaCentroCosto && (
              <b>{`Centro de Costo.: ${documento.centroCostoNombre}${
                documento.centroCostoProyectoNombre
                  ? `, Proyecto.: ${documento.centroCostoProyectoNombre}`
                  : ""
              } ${
                documento.centroCostoSubProyectoNombre
                  ? `, Sub-Proyecto.: ${documento.centroCostoSubProyectoNombre}`
                  : ""
              }`}</b>
            )}
          </Grid>
        </Grid>

        <div style={{ height: "10px" }}></div>

        {/* Informacion */}
        <Grid container item spacing={1}>
          <Grid item xs={4}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "120px" }}>RNC / Cédula.:</td>
                  <td style={{ width: "120px", borderBottom: "0.5px solid" }}>
                    {documento.clienteRncCedula}
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "120px" }}>Cliente.:</td>
                  <td style={{ width: "120px", borderBottom: "0.5px solid" }}>
                    {documento.clienteDestinatario}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>

          <Grid item xs={4}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "120px" }}>Frecuencia de pago.:</td>
                  <td style={{ width: "120px", borderBottom: "0.5px solid" }}>
                    {documento.frecuenciaNombre}
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "150px" }}>Monto de Prestamo.:</td>
                  <td
                    style={{ width: "100px", borderBottom: "0.5px solid" }}
                    align="right"
                  >
                    {FormatearNumero.format(documento.montoPrestamo)}
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "150px" }}>Tasa de Interes Anual.:</td>
                  <td
                    style={{ width: "100px", borderBottom: "0.5px solid" }}
                    align="right"
                  >
                    {FormatearNumero.format(documento.tasaInteresAnual)}%
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>

          <Grid item xs={4}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "150px" }}>Monto Gastos de Cierre.:</td>
                  <td
                    style={{ width: "100px", borderBottom: "0.5px solid" }}
                    align="right"
                  >
                    {FormatearNumero.format(documento.montoGastosCierre)}
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "150px" }}>Monto Gastos Legales.:</td>
                  <td
                    style={{ width: "100px", borderBottom: "0.5px solid" }}
                    align="right"
                  >
                    {FormatearNumero.format(documento.montoGastosLegales)}
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "150px" }}>Cantidad de Cuotas.:</td>
                  <td
                    style={{ width: "100px", borderBottom: "0.5px solid" }}
                    align="right"
                  >
                    {documento.cantidadCuotas}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>

      <div>
        {/* Productos */}
        {documento.cuotas?.length !== 0 && (
          <Grid container item>
            <Grid item xs={12}>
              <table style={{ width: "100%" }}>
                <thead style={{ border: "1px solid black" }}>
                  <tr>
                    <th style={{ width: "100px" }}>Cuota No.</th>
                    <th style={{ width: "100px" }}>Fecha</th>
                    <th style={{ width: "auto", textAlign: "right" }}>
                      Monto Pago
                    </th>
                    <th style={{ width: "auto", textAlign: "right" }}>
                      Monto Inicial
                    </th>
                    <th style={{ width: "auto", textAlign: "right" }}>
                      Monto Capital
                    </th>
                    <th style={{ width: "auto", textAlign: "right" }}>
                      Monto Interes
                    </th>
                    <th style={{ width: "auto", textAlign: "right" }}>
                      Monto Final
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {documento?.cuotas &&
                    documento.cuotas.map((pr) => (
                      <tr key={pr.id}>
                        <td>{pr.cuotaNo}</td>
                        <td>
                          {FormatearFecha(
                            convertirTextoAFecha(pr.fecha),
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td style={{ textAlign: "right", padding: "5px" }}>
                          {FormatearNumero.format(pr.montoPago)}
                        </td>
                        <td style={{ textAlign: "right", padding: "5px" }}>
                          {FormatearNumero.format(pr.montoInicial)}
                        </td>
                        <td style={{ textAlign: "right", padding: "5px" }}>
                          {FormatearNumero.format(pr.montoCapital)}
                        </td>
                        <td style={{ textAlign: "right", padding: "5px" }}>
                          {FormatearNumero.format(pr.montoInteres)}
                        </td>
                        <td style={{ textAlign: "right", padding: "5px" }}>
                          {FormatearNumero.format(pr.montoFinal)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                ********************ULTIMA LINEA********************
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={4}>
          <table style={{ captionSide: "top", fontSize: "6pt" }}>
            <caption>
              <Typography color="textSecondary" align="center">
                Resumen del Prestamo
              </Typography>
            </caption>

            <tbody>
              <tr>
                <td style={{ width: "170px" }}>Tipo Saldo.:</td>
                <td style={{ width: "80px" }} align="right">
                  {documento.tipoCalculo === 0 ? "Insoluto" : "Soluto"}
                </td>
              </tr>

              <tr>
                <td style={{ width: "170px" }}>Monto Solicitado.:</td>
                <td style={{ width: "80px" }} align="right">
                  {FormatearNumero.format(documento.montoPrestamo)}
                </td>
              </tr>

              <tr>
                <td style={{ width: "170px" }}>
                  Monto Total de Gastos de Cierre.:
                </td>
                <td style={{ width: "80px" }} align="right">
                  {sumarGastos ? "+" : "-"}
                  {FormatearNumero.format(documento.montoGastosCierre)}
                </td>
              </tr>

              <tr>
                <td style={{ width: "170px" }}>
                  Monto Total de Gastos Legales.:
                </td>
                <td style={{ borderBottom: "solid" }} align="right">
                  {sumarGastos ? "+" : "-"}
                  {FormatearNumero.format(documento.montoGastosLegales)}
                </td>
              </tr>

              <tr>
                <td style={{ width: "170px" }}>
                  <Typography style={{ fontSize: "small" }} color="error">
                    Valor a Desembolsar.:
                  </Typography>
                </td>
                <td align="right">
                  <Typography style={{ fontSize: "small" }} color="error">
                    {FormatearNumero.format(documento.montoDesembolsar)}
                  </Typography>
                </td>
              </tr>

              <tr>
                <td style={{ width: "170px" }}>Monto Total de Intereses.:</td>
                <td style={{ borderBottom: "solid" }} align="right">
                  +{FormatearNumero.format(documento.montoInteres)}
                </td>
              </tr>

              <tr>
                <td style={{ width: "170px" }}>
                  <Typography style={{ fontSize: "small" }} color="primary">
                    Valor Total del Prestamo.:
                  </Typography>
                </td>
                <td align="right">
                  <Typography style={{ fontSize: "small" }} color="primary">
                    {FormatearNumero.format(documento.total)}
                  </Typography>
                </td>
              </tr>

              <tr>
                <td style={{ width: "170px" }}>
                  <Typography style={{ fontSize: "small" }} color="secondary">
                    Pago Mensual.:
                  </Typography>
                </td>
                <td align="right">
                  <Typography style={{ fontSize: "small" }} color="secondary">
                    {FormatearNumero.format(documento.montoPago)}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </div>

      <div>
        {/* Pie de página */}
        <Grid container item spacing={0} className="impresionPie">
          <Grid container item spacing={0}>
            {documento.observacion && (
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <p>Notas: {documento.observacion}</p>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <hr style={{ border: "0.5px solid black" }} />
            </Grid>

            <Grid item xs={4} style={{ paddingLeft: 20, paddingRight: 20 }}>
              <div style={{ height: "100px" }}></div>
              <hr
                style={{
                  borderBottom: "1px solid black",
                  textAlign: "center",
                }}
              />
              <div style={{ textAlign: "center", width: "100%" }}>
                Autorizado Por
              </div>
            </Grid>

            <Grid item xs={4} style={{ paddingLeft: 20, paddingRight: 20 }}>
              <div style={{ height: "100px" }}></div>
              <hr
                style={{
                  borderBottom: "1px solid black",
                  textAlign: "center",
                }}
              />
              <div style={{ textAlign: "center", width: "100%" }}>
                Recibido Por
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
