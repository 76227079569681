import React, { useContext } from "react";

import { Autocomplete } from "@material-ui/lab";
import { authContext } from "../../../MainComponent";

import { TextField } from "../";
import { ITiposDeDocumento } from "../../../../AppInterfaces";

export function AutocompleteTipoDeDocumento({
  documentId,
  onChange,
  required = false,
  disabled = false,
  error,
  label = "Tipo de Documento",
  filter,
}: {
  documentId?: string;
  onChange: (
    documento?: ITiposDeDocumento,
    documentosList?: ITiposDeDocumento[]
  ) => void;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  label?: string;
  filter?: (value: ITiposDeDocumento) => boolean;
}) {
  const { tiposDeDocumento: registros = [] } = useContext(authContext);

  const buscaDocumentoPorId = (docId?: string) =>
    registros.find((reg) => reg.id === docId);

  return (
    <Autocomplete
      options={
        registros
          .filter((reg) => (filter ? filter(reg) : true))
          .sort((a, b) => -b.nombre[0].localeCompare(a.nombre[0]))
          .map((reg) => ({ docId: reg.id ?? "" })) ?? [{ docId: "" }]
      }
      groupBy={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre[0];
        }

        return "";
      }}
      getOptionLabel={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre;
        }

        return "";
      }}
      getOptionSelected={(option, value) => option.docId === value.docId}
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          required={required}
          error={error}
          label={label}
          name="tipoDocumentoNombre"
        />
      )}
      onChange={(e, value) =>
        onChange(buscaDocumentoPorId(value?.docId), registros)
      }
      value={{ docId: documentId || "" }}
      disabled={disabled}
    />
  );
}
