const tourPasos = [
  {
    selector: "#botonNuevoDocumento",
    content: "Para crear un nuevo documento pulsa este botón.",
  },
  {
    selector: "#botonModificarDocumento",
    content: "Para modificar el documento pulsa este botón.",
  },
  {
    selector: "#botonEliminar",
    content: "Para eliminar el documento pulsa este botón.",
  },
  {
    selector: "#botonGuardar",
    content: "Para guardar el documento pulsa este botón.",
  },
  {
    selector: "#botonCancelar",
    content: "Para cancelar los cambios realizados pulsa este botón.",
  },
  {
    selector: "#botonImprimir",
    content: "Para imprimir el documento pulsa este botón.",
  },
  {
    selector: "#botonRefrescar",
    content: "Para calcular las cuotas del prestamo pulsa este botón.",
  },
  {
    selector: "#fecha",
    content: "Fecha de creación del documento.",
  },
  {
    selector: "#fechaInicio",
    content:
      "Fecha de inicio del prestamo. Este variará dependiendo de la fecha de desembolso del prestamo.",
  },
  {
    selector: "#tipoCalculo",
    content: "Indica como calcular las cuotas por saldo Insoluto o Soluto.",
  },
  {
    selector: "#clienteNombre",
    content: "Indica el cliente al cual se le cargará el prestamo indicado.",
  },
  {
    selector: "#frecuenciaNombre",
    content: "Frecuencia de pago para el prestamo.",
  },
  {
    selector: "#observacion",
    content: "Breve descripción del documento.",
  },
  {
    selector: "#montoPrestamo",
    content: "Monto solicitado del prestamo.",
  },
  {
    selector: "#tasaInteresAnual",
    content: "Tasa de interes anual para el calculo de interes por pagar.",
  },
  {
    selector: "#montoGastosCierre",
    content: "Gastos a rebajar del prestamo por cierre.",
  },
  {
    selector: "#montoGastosLegales",
    content: "Gastos a rebajar del prestamo por temas legales.",
  },
  {
    selector: "#cantidadCuotas",
    content: "Cantidad de pagos a realizar.",
  },
];

export default tourPasos;
