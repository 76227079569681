import React, { useContext } from "react";

import { Autocomplete } from "@material-ui/lab";
import { authContext } from "../../../MainComponent";

import { TextField } from "../";
import { IContabilidadCatalogo } from "../../../../AppInterfaces";
import { useQuery } from "react-query";
import { Consultar } from "../../server/funcionesServidor";

export function AutocompleteCuentas({
  documentId,
  onChange,
  required = false,
  disabled = false,
  error,
  label = "Cta. Contable",
  mostrarInactivos,
  name = "cuenta",
  modificando = true,
}: {
  documentId?: number;
  onChange: (
    documento?: IContabilidadCatalogo,
    documentosList?: IContabilidadCatalogo[]
  ) => void;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  label?: string;
  mostrarInactivos?: boolean;
  name?: string;
  modificando?: boolean;
}) {
  const {
    companiaSeleccionada: { id: companiaId },
  } = useContext(authContext);

  const { data: registros = [] } = useQuery(
    "ContabilidadCatalogos",
    async () =>
      await Consultar<IContabilidadCatalogo>(
        "api/ContabilidadCatalogos/consultar",
        undefined,
        undefined,
        {
          where: `companiaId = ${companiaId}`,
        }
      )
  );

  const buscaDocumentoPorId = (docId?: number) =>
    registros.find((reg) => reg.id === docId);

  return modificando ? (
    <Autocomplete
      options={
        registros
          .filter((reg) => (mostrarInactivos ? true : !reg.isInactivo))
          .sort((a, b) => -b.nombre[0].localeCompare(a.nombre[0]))
          .map((reg) => ({ docId: reg.id ?? 0 })) ?? [{ docId: 0 }]
      }
      groupBy={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre[0];
        }

        return "";
      }}
      getOptionLabel={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre;
        }

        return "";
      }}
      getOptionSelected={(option, value) => option.docId === value.docId}
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          required={required}
          error={error}
          label={label}
          name={name}
        />
      )}
      onChange={(e, value) =>
        onChange(buscaDocumentoPorId(value?.docId), registros)
      }
      value={{ docId: documentId || 0 }}
      disabled={disabled}
    />
  ) : (
    <div>
      <div>
        <strong>{label}</strong>
      </div>
      <div>
        {(buscaDocumentoPorId(documentId)?.cuenta || "") +
          " - " +
          (buscaDocumentoPorId(documentId)?.nombre || "")}
      </div>
    </div>
  );
}
