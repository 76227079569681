import React, { Component } from "react";
import "./App.css";
import "./styles/tables.css";
import { BrowserRouter } from "react-router-dom";
import Main from "./components/MainComponent";
import LoginTecnico from "./components/ordenServicioTecnicos/LoginTecnico/LoginTecnico";
import { ToastContainer } from "react-toastify";
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ToastContainer />

        {window.location.pathname === '/orden-servicio-tecnicos' ? (
          <>
            <LoginTecnico
              rutaIcono={'logo192.png'}
            />
          </>
        ) : <Main />}

      </BrowserRouter>
    );
  }
}

export default App;
