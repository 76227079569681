import React, { useContext } from "react";

import { Autocomplete } from "@material-ui/lab";
import { authContext } from "../../../MainComponent";

import { TextField } from "../";
import { ITallerMecanicaOrdenTipo } from "../../../../AppInterfaces";
import { useQuery } from "react-query";
import { Consultar } from "../../server/funcionesServidor";

export function AutocompleteTallerMecanicaTipoOrden({
  documentId,
  onChange,
  required = false,
  disabled = false,
  error,
  label = "Tipo de Orden",
  mostrarInactivos,
}: {
  documentId?: number;
  onChange: (
    documento?: ITallerMecanicaOrdenTipo,
    documentosList?: ITallerMecanicaOrdenTipo[]
  ) => void;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  label?: string;
  mostrarInactivos?: boolean;
}) {
  const {
    companiaSeleccionada: { id: companiaId },
  } = useContext(authContext);

  const { data: registros } = useQuery(
    "TallerMecanicaOrdenTipos",
    async () =>
      await Consultar<ITallerMecanicaOrdenTipo>(
        `api/TallerMecanicaOrdenTipos/consultar`,
        undefined,
        undefined,
        {
          where: `companiaId = ${companiaId} and isInactivo = 0`,
          order: "nombre asc",
        }
      )
  );

  const buscaDocumentoPorId = (docId?: number) =>
    registros?.find((reg) => reg.id === docId);

  if (!registros) return null;

  return (
    <Autocomplete
      options={
        registros
          .filter((reg) => (mostrarInactivos ? true : !reg.isInactivo))
          .sort((a, b) => -b.nombre[0].localeCompare(a.nombre[0]))
          .map((reg) => ({ docId: reg.id ?? 0 })) ?? [{ docId: 0 }]
      }
      groupBy={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre[0];
        }

        return "";
      }}
      getOptionLabel={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre;
        }

        return "";
      }}
      getOptionSelected={(option, value) => option.docId === value.docId}
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          required={required}
          error={error}
          label={label}
          name="tipoOrdenNombre"
        />
      )}
      onChange={(e, value) =>
        onChange(buscaDocumentoPorId(value?.docId), registros)
      }
      value={{ docId: documentId || 0 }}
      disabled={disabled}
    />
  );
}
