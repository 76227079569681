import React from "react";
import {
  CardContent,
  Grid,
  Card,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import { IFinanciamientoPrestamo } from "../../../../../AppInterfaces";

import {
  FormatearFecha,
  convertirTextoAFecha,
  FormatearNumero,
} from "../../../../common/funciones/funciones";

export default function Detalle({
  documento,
}: {
  documento: IFinanciamientoPrestamo;
}) {
  return (
    <>
      <Grid item xs={12}>
        <Card className="cardStyle3" id="cardDetalleProductos">
          <CardHeader title="Cuotas a pagar" />

          <CardContent>
            <Grid container item spacing={1}>
              <Grid item xs={12} className="tablasDetalles">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Cuota No.</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell align="right">Monto Pago</TableCell>
                      <TableCell align="right">Monto Inicial</TableCell>
                      <TableCell align="right">Monto Capital</TableCell>
                      <TableCell align="right">Monto Interes</TableCell>
                      <TableCell align="right">Monto Final</TableCell>
                    </TableRow>
                  </TableHead>

                  {documento.cuotas && (
                    <TableBody>
                      {documento.cuotas
                        .filter((cuota) => !cuota.eliminar)
                        .map((registro, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Grid item xs={12}>
                                {registro.cuotaNo}
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Grid item xs={12}>
                                {FormatearFecha(
                                  convertirTextoAFecha(registro.fecha),
                                  "DD-MM-YYYY"
                                )}
                              </Grid>
                            </TableCell>
                            <TableCell align="right">
                              <Grid item xs={12}>
                                {FormatearNumero.format(registro.montoPago)}
                              </Grid>
                            </TableCell>
                            <TableCell align="right">
                              <Grid item xs={12}>
                                {FormatearNumero.format(registro.montoInicial)}
                              </Grid>
                            </TableCell>
                            <TableCell align="right">
                              <Grid item xs={12}>
                                {FormatearNumero.format(registro.montoCapital)}
                              </Grid>
                            </TableCell>
                            <TableCell align="right">
                              <Grid item xs={12}>
                                {FormatearNumero.format(registro.montoInteres)}
                              </Grid>
                            </TableCell>
                            <TableCell align="right">
                              <Grid item xs={12}>
                                {FormatearNumero.format(registro.montoFinal)}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
