import React, { useContext } from "react";

import { Autocomplete } from "@material-ui/lab";
import { authContext } from "../../../MainComponent";

import { TextField } from "../";
import { ICxCRestaurantMesa } from "../../../../AppInterfaces";

export function AutocompleteRestaurantMesas({
  documentId,
  onChange,
  required = false,
  disabled = false,
  error,
  label = "Mesa",
  mostrarInactivos,
  modificando = true,
  noZeroOption,
}: {
  documentId?: number;
  onChange: (
    documento?: ICxCRestaurantMesa,
    documentosList?: ICxCRestaurantMesa[]
  ) => void;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  label?: string;
  mostrarInactivos?: boolean;
  modificando?: boolean;
  noZeroOption?: boolean;
}) {
  const {
    companiaSeleccionada: { CxCRestaurantMesas: registros },
  } = useContext(authContext);

  const buscaDocumentoPorId = (docId?: number) =>
    registros.find((reg) => reg.id === docId);

  return modificando ? (
    <Autocomplete
      options={
        registros
          .filter((reg) => (mostrarInactivos ? true : !reg.isInactivo))
          .filter((reg) => (noZeroOption ? !!reg.id : true))
          .sort((a, b) => -b.nombre[0].localeCompare(a.nombre[0]))
          .map((reg) => ({ docId: reg.id ?? 0 })) ?? [{ docId: 0 }]
      }
      groupBy={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre[0];
        }

        return "";
      }}
      getOptionLabel={(option) => {
        const reg = buscaDocumentoPorId(option.docId);

        if (reg) {
          return reg.nombre + " | Ubicación: " + reg.ubicacion;
        }

        return "";
      }}
      getOptionSelected={(option, value) => option.docId === value.docId}
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          required={required}
          error={error}
          label={label}
          name="mesaNombre"
        />
      )}
      onChange={(e, value) =>
        onChange(buscaDocumentoPorId(value?.docId), registros)
      }
      value={{ docId: documentId || 0 }}
      disabled={disabled}
    />
  ) : (
    <div>
      <div>
        <strong>{label}</strong>
      </div>
      <div>{buscaDocumentoPorId(documentId)?.nombre || ""}</div>
    </div>
  );
}
